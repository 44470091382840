@import '../../../../assets/styles/variables';

.constructor-question {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 50px 80px;

  @include for-size(tablet-phone) {
    flex: 1 0 auto;
    padding: 35px 30px;
  }

  &-progress-bar {
    width: 100%;
    height: 12px;
    border-radius: 60px;
    margin-bottom: 50px;

    &::-webkit-progress-bar {
      background-color: #F2F2F2;
      border-radius: 60px;
    }

    &::-webkit-progress-value {
      border-radius: 60px;
      background-color: #FE6D02;
    }
  }

  &-title {
    white-space: pre-wrap;
    font-family: "SFProDisplay-Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: black;
    max-width: 500px;
    display: block;
    margin: 0 auto;

    @include for-size(phone) {
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      color: #000000;
    }
  }

  &-subtitle {
    white-space: pre-wrap;
    font-family: 'SFProDisplay-Semibold', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    max-width: 500px;
    margin: 0 auto;

    @include for-size(phone) {
      font-weight: 500;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 20px;
    flex: 1 0 auto;

    input::placeholder {
      color: #AAAAAA !important;
    }

    input:focus::placeholder {
      opacity: 0;
    }

    input {
      color: black !important;
    }
  }

  &-buttons {
    width: 315px;
    margin: 40px auto 0;

    &-thank {
      font-weight: 600;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #12121D;
    }
  }

  &-description {
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    max-width: 505px;
    margin: 0 auto;
    span {
      font-weight: bold;
    }
    @include for-size(phone) {
      text-align: start;

      font-style: normal;
      font-weight: 400;
    }
  }

  &-got {
    background: black;
    color: white;
    margin-bottom: 16px;
  }

  &-skip {
    font-family: 'SFProDisplay-Regular', sans-serif;
    font-weight: 400;
    opacity: 1;
    padding: 0;
    background: none;
    width: auto;
    color: #B9B9B9;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;

    @include for-size(small-phone) {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }
  }
}
