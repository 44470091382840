@font-face {
  font-family: 'SFProDisplay-Regular';
  font-weight: 400;
  font-style: normal;
  src: local('Golos'), url(../fonts/SFProDisplay-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'SFProDisplay-Medium';
  font-weight: 400;
  font-style: normal;
  src: local('Golos'), url(../fonts/SFProDisplay-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'SFProDisplay-Semibold';
  font-weight: 600;
  font-style: normal;
  src: local('Golos'),
    url(../fonts/SFProDisplay-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'SFProDisplay-Bold';
  font-weight: 800;
  font-style: normal;
  src: local('Golos'),
  url(../fonts/SFProDisplay-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueCyr-UltraLight';
  src: url('../fonts/helveticaneuecyr-ultralight.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/helveticaneuecyr-ultralight.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/helveticaneuecyr-ultralight.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helveticaneuecyr-ultralight.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/helveticaneuecyr-ultralight.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helveticaneuecyr-ultralight.svg#helveticaneuecyr-ultralight') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'HelveticaNeueCyr-Black';
  src: url('../fonts/helveticaneuecyr-black.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/helveticaneuecyr-black.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/helveticaneuecyr-black.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helveticaneuecyr-black.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/helveticaneuecyr-black.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helveticaneuecyr-black.svg#helveticaneuecyr-black') format('svg'); /* Chrome < 4, Legacy iOS */
}


@font-face {
  font-family: 'HelveticaNeueCyr-Bold';
  src: url('../fonts/helveticaneuecyr-bold.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/helveticaneuecyr-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/helveticaneuecyr-bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helveticaneuecyr-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/helveticaneuecyr-bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helveticaneuecyr-bold.svg#helveticaneuecyr-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'HelveticaNeueCyr-Heavy';
  src: url('../fonts/helveticaneuecyr-heavy.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/helveticaneuecyr-heavy.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/helveticaneuecyr-heavy.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helveticaneuecyr-heavy.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/helveticaneuecyr-heavy.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helveticaneuecyr-heavy.svg#helveticaneuecyr-heavy') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'HelveticaNeueCyr-Light';
  src: url('../fonts/helveticaneuecyr-light.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/helveticaneuecyr-light.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/helveticaneuecyr-light.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helveticaneuecyr-light.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/helveticaneuecyr-light.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helveticaneuecyr-light.svg#helveticaneuecyr-light') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'HelveticaNeueCyr-Medium';
  src: url('../fonts/helveticaneuecyr-medium.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/helveticaneuecyr-medium.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/helveticaneuecyr-medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helveticaneuecyr-medium.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/helveticaneuecyr-medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helveticaneuecyr-medium.svg#helveticaneuecyr-medium') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'HelveticaNeueCyr-Thin';
  src: url('../fonts/helveticaneuecyr-thin.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/helveticaneuecyr-thin.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/helveticaneuecyr-thin.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/helveticaneuecyr-thin.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/helveticaneuecyr-thin.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/helveticaneuecyr-thin.svg#helveticaneuecyr-thin') format('svg'); /* Chrome < 4, Legacy iOS */
}


