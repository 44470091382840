@import '../../../../assets/styles/variables';

.registration-page {
  position: relative;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  width: 420px;
  padding: 40px 45px;
  border-radius: 28px;
  margin: auto;

  @include for-size(small-phone) {
    width: 335px;
    padding: 45px 20px 20px;
    margin: 120px 20px;
  }

  &-logo {
    filter: invert(1);
    height: 30px;
    margin: 0;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, -100%);

    @include for-size(small-phone) {
      height: 17px;
      top: -45px;
    }
  }

  form {
    input:focus {
      border: 1px solid #FE6D02 !important;

      & + img {
        filter: invert(44%) sepia(69%) saturate(2028%) hue-rotate(359deg) brightness(102%) contrast(107%);
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    margin: 0 auto 30px;
    display: block;
    font-family: 'SFProDisplay-Bold', sans-serif;

    @include for-size(small-phone) {
      text-align: left;
      margin: 0 auto 25px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    flex-direction: column;

    input {
      color: black;
    }

    input, input::placeholder {
      font-size: 16px;
      line-height: 24px;
    }

    &__password_wrapper {

      @include for-size(small-phone) {
        .errors {
          top: calc(100% + 10px);
        }
      }

      .errors {
        top: calc(100% + 5px);
      }
    }

    &__password {
      &__hint {
        font-size: 12px;
        color: rgba(0, 0, 0, .4);
      }
    }

  }

  &__agreement {
    margin: 47px 0 20px;
    display: flex;
    align-items: center;
    min-width: 326px;

    @include for-size(tablet-phone) {
      min-width: auto;
    }

    @include for-size(small-phone) {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &-title {
      margin: 0 0 0 8px;
      font-size: 12px;
      line-height: 16px;
    }

    &-subtitle:nth-child(1),
    &-subtitle:nth-child(3) {
      color: $color-text-primary;
    }

    &-subtitle:nth-child(2),
    &-subtitle:nth-child(4) {
      color: black;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &_signup_button {
    margin-top: 30px;
    background: #FE6D02;
    margin-bottom: 15px;

    @include for-size(small-phone) {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  &__to-registration {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    flex-direction: column;

    &-title, &-btn {
      font-size: 12px;
      line-height: 16px;
      color: $color-text-primary;
    }

    &-btn {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
