@import "../../assets/styles/mixin";

.view_plan {
  border-radius: 60px;
  background: white;
  padding: 50px 90px 30px;
  flex: 1 1 100%;

  @include for-size(tablet-phone) {
    padding: 30px 30px;
    border-radius: 0;
  }

  &__header {
    display: flex;

    @include for-specific-size(660px) {
      flex-direction: column;
      row-gap: 20px;
    }

    b {
      font-family: "SFProDisplay-Bold", sans-serif;
      font-style: normal;
      font-size: 28px;
      line-height: 32px;
      text-transform: capitalize;
      color: #000;
      margin-right: 40px;
      max-width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include for-specific-size(660px) {
        font-size: 20px;
        line-height: 32px;

        br {
          display: none;
          margin: 0;
          padding: 0;
        }
      }

      @include for-size(tablet-phone) {
        max-width: 100%;
        padding-left: 36px;
        position: relative;
        font-size: 20px;
        margin-right: 15px;
        &:before {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.26239 15.5641L15.564 8.26245M19.0227 11.7211L11.7211 19.0228M8.26239 22.4814L6.80206 23.9418C6.64834 24.0955 6.41776 24.0955 6.26404 23.9418L3.34339 21.0211C3.18967 20.8674 3.18967 20.6368 3.34339 20.4831L4.80371 19.0228M19.0227 4.80378L20.483 3.34345C20.6368 3.18973 20.8673 3.18973 21.0211 3.34345L23.9417 6.26411C24.0954 6.41783 24.0954 6.64841 23.9417 6.80213L22.4814 8.26245M14.4496 22.0587L11.2983 25.21C11.1062 25.4021 10.7988 25.4021 10.6066 25.21L1.88306 16.4864C1.69091 16.2943 1.69091 15.9868 1.88306 15.7947L5.03429 12.6434C5.22644 12.4513 5.53388 12.4513 5.72603 12.6434L14.4496 21.367C14.6417 21.5591 14.6417 21.8666 14.4496 22.0587ZM25.2099 11.2984L22.0587 14.4496C21.8665 14.6418 21.5591 14.6418 21.3669 14.4496L12.6434 5.72609C12.4512 5.53394 12.4512 5.22651 12.6434 5.03436L15.7946 1.88312C15.9868 1.69097 16.2942 1.69097 16.4863 1.88312L25.2099 10.6067C25.402 10.7988 25.402 11.1063 25.2099 11.2984Z' stroke='%23FE6D02' stroke-width='1.7' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
          width: 27px;
          height: 27px;
          position: absolute;
          left: 0px;
          top: 5px;
        }
      }
    }

    &__description {
      flex: 0 0 375px;
      padding-left: 30px;
      border-left: 1px solid rgba(#B8C2C0, .35);

      @include for-specific-size(660px) {
        flex: 0 0 auto;
        padding-left: 0;
        border-left: none;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000;
        font-family: 'SFProDisplay-Semibold', sans-serif;
        @include for-specific-size(660px) {
          display: none;
        }
      }

      small {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #8F8F8F;
      }
    }
  }

  &__weeks_list {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    &__week {
      padding-top: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(#B9B9B9, .4);

      &_header {
        display: flex;
        padding-top: 5px;
        padding-bottom: 15px;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;

        p {
          font-family: 'SFProDisplay-Semibold';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
        }
      }

      &__days_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 20px;

        &__day {
          flex: 0 0 30%;
          width: auto;
          border: 1px solid #F2F2F2;
          box-sizing: border-box;
          border-radius: 20px;
          height: 90px;
          display: flex;
          column-gap: 22px;

          @include for-specific-size(1340px) {
            flex: 0 0 45%;
          }

          @include for-specific-size(720px) {
            flex: 0 0 100%;
          }

          @include for-size(small-phone) {
            min-width: 300px;
          }

          img {
            border-radius: 20px;
            height: inherit;
            width: 100px;
            object-fit: cover;
          }

          &__info_wrapper {
            position: relative;
            overflow: hidden;
            flex: 1 1 auto;
            padding: 10px 10px 10px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &_title {
              white-space: nowrap;
              font-family: 'SFProDisplay-Semibold', sans-serif;
              overflow: hidden;
              text-overflow: ellipsis;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: #000000;
            }

            &_percent {
              height: 45px;
              width: 45px;
              position: absolute;
              right: 5px;
              top: 5px;

              span {
                padding-bottom: 2px;
                padding-left: 12px;
                font-size: 8px;

                @include for-specific-size(1024px) {
                  padding-bottom: 2px;
                  padding-left: 12px;
                  font-size: 8px !important;
                }
              }
            }

            &__info {
              display: flex;
              justify-content: space-between;

              &_time {
                position: relative;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 24px;
                color: #8F8F8F;
                padding-left: 23px;

                &:before {
                  position: absolute;
                  left: 0;
                  margin-top: 2px;
                  content: url("../../assets/images/myPlan/clock.svg");
                }

                &_dayOff {
                  position: relative;
                  height: 20px;

                  &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin-top: 2px;
                    content: url("../../assets/images/myPlan/dayOff.svg");
                  }
                }
              }

              &_day_number {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 24px;
                color: #8F8F8F;

                @include for-size(small-phone) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.view_plan__weeks_list__week.view_plan__open {
  padding-bottom: 20px;
}

div.view_plan__open {
  height: auto;
}

img.view_plan__open {
  transform: rotate(90deg);
}
