@import '../../assets/styles/variables';

.profile {
  display: flex;
  background: #ffffff;
  border-radius: 60px;
  flex: 1 1 auto;
  width: 100%;
  @include for-size(tablet-phone) {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__photo {
    width: 195px;
    height: 195px;
    border-radius: 100%;
  }

  &__left {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0;
    border-right: 1px solid #b8c2c0;
    @include for-size(tablet-phone) {
      width: 100%;
      border: none;
      text-align: center;
    }

    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &_avatar {
        position: relative;
        margin-bottom: 20px;
        width: 195px;
        height: 195px;

        input {
          opacity: 0;
          left: 0;
          cursor: pointer;
          position: absolute;
          height: 100%;
          width: 100%;
        }

        input:hover + span {
          color: rgba(0, 0, 0, 0);
          background: white !important;
        }

        input:hover + span:after {
          opacity: 1;
        }

        img, span {
          width: 100%;
          height: 100%;
          pointer-events: none;
          border-radius: 50%;
        }

        img {
          object-fit: cover;
        }

        span {
          box-sizing: border-box;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 8px solid #FE6D02;
          font-size: 96px;
          text-transform: uppercase;
          font-weight: bold;
          color: white;
          transition: .3s all;
          background: black;

          &:after {
            transition: .3s all;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            opacity: 0;
            background: url("../../assets/images/profile/add-photo.png") no-repeat center center;
            background-size: contain;
          }
        }

        &__settings {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 12px;
          margin-bottom: 20px;

          button, small {
            cursor: pointer;
          }

          button {
            padding-top: 12px;
            padding-bottom: 12px;
            width: 100%;
            border: none;
            background: #000;
            color: #fff;
            border-radius: 60px;
            font-weight: bold;
            font-family: 'SFProDisplay-Semibold', sans-serif;
            font-size: 16px;
            line-height: 24px;
          }

          small:hover {
            text-decoration: underline;
          }
        }
      }

      &_name {
        font-family: 'SFProDisplay-Bold', sans-serif;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        color:#000;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_email {
        margin-top: 7px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #8f8f8f;
      }
    }

    &-button {

      @include for-specific-size(1024px) {
        display: none;
      }

      @include for-size(phone) {
        position: absolute;
        bottom: 60px;
        left: 20px;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        text-decoration: none;

        p {
          margin: 0 5px;
          color: $color-text-logout;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
        }

        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 75%;

    @include for-size(tablet-phone) {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &__menu {
    list-style: none;

    @include for-specific-size(1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

      &__button {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: none;
        justify-content: flex-start;
        text-decoration: none;

        @include for-specific-size(1024px) {
          display: flex;
        }

        p {
          margin: 0 5px;
          font-family: 'SFProDisplay-Medium', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }

        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }

    &-item {
      width: 75%;
      margin: 0 30px 0 0;
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #B8C2C0;
      position: relative;
      cursor: pointer;

      @include for-size(phone) {
        width: 100%;
      }

      a {
        text-decoration: none;
        width: 100%;
        height: 100%;
        padding: 40px;
        font-family: 'SFProDisplay-Medium', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;

        color: #000000;
        @include for-size(tablet-phone) {
          padding: 40px 0;
        }
      }

      &:hover {
        background: #f2f2f2;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 2%;
        width: 5px;
        height: 5px;
        border-bottom: 1.5px solid #FE6D02;
        border-right: 1.5px solid #FE6D02;
        transform: translateX(-50%) rotate(-45deg);
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin: 0;
      }
    }
  }
}
