@import '../../../../assets/styles/variables';

.credit-card {
  margin: 0;
  box-sizing: border-box;
  max-width: 460px;
  background: #FFFFFF;
  padding: 60px 70px;
  border-radius: 47px;
  display: flex;
  flex-direction: column;

  @include for-size(small-phone) {
    padding: 50px 30px 0 30px;
  }

  input {
    background: none;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:focus {
      border-bottom: 1px solid #FE6D02 !important;
    }
  }

  @include for-size(desktop-laptop) {
    max-width: 100%;
    width: 100%;
  }

  &-title {
    font-weight: bold;
    font-size: 48px;
    line-height: 55px;
    color: rgba(18, 18, 29, 0.8);
    text-align: center;
    margin: 0;
    @include for-size(tablet-phone) {
      display: none;
    }
  }

  &-description {
    font-size: 18px;
    line-height: 24px;
    color: rgba(18, 18, 29, 0.6);
    text-align: center;
    margin-bottom: 30px;
    @include for-size(tablet-phone) {
      display: none;
    }
  }

  &__container {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    width: 45%;
    position: relative;

    &-error {
      bottom: 0;
      transform: translate(0, 100%);
      padding-top: 5px;
      position: absolute;
      color: #f22;
    }

    &-label {
      margin-bottom: 7px;
      font-size: 16px;
      line-height: 24px;
      color: rgba(18, 18, 29, 0.3);
    }

    &-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(18, 18, 29, 0.1);
      background: #f2f2f2;
      outline: none;
      color: rgba(18, 18, 29, 0.3);
      padding: 0;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px #f2f2f2 inset !important;
        background: #f2f2f2 !important;
      }
    }

    &-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 5px;
      right: 5px;
      cursor: pointer;

      path {
        transition: .3s all;
      }

      path:first-child {
        opacity: 1;
      }

      path:last-child {
        opacity: 0;
      }
    }

    &-icon-shown {
      path:first-child {
        opacity: 0;
      }

      path:last-child {
        opacity: 1;
        fill-opacity: 1;
      }
    }

    &-card {
      width: 100%;
    }
  }

  &__button {
    margin-top: 50px;
    margin-bottom: 10px;
    background: #FE6D02;
  }

  &-trial {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    line-height: 18px;
    color: $color-text-primary;
    text-align: center;
    max-width: 225px;

    @include for-size(desktop-laptop) {
      margin-bottom: 20px;
    }

    @include for-size(small-phone) {
      max-width: 280px;
    }
  }

  &-info {
    display: block;
    margin-top: auto;
    font-size: 11px;
    line-height: 17px;
    color: rgba(18, 18, 29, 0.3);
    border-top: 1px solid rgba(#B8C2C0, .35);
    padding-top: 15px;

    @include for-size(desktop-laptop) {
      text-align: center;
    }
  }
}
