@import '../../assets/styles/variables';

$padding: 100px;
$padding-1520: 80px;
$padding-1440: 60px;
$padding-1280: 40px;
$padding-905: 20px;
$padding-375: 15px;

.landing {
  width: 100%;
  background: #EDF3F4;
  font-family: "HelveticaNeueCyr-Medium", sans-serif;
  overflow: hidden;


  * {
    box-sizing: border-box;
    transition: .3s all;
  }

  button, a {
    cursor: pointer;
  }

  &__intro-banner {
    margin-top: -100px;
    margin-bottom: 240px;
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    min-height: 720px;

    @include for-specific-size(1520px) {
      margin-bottom: 230px;
    }

    @include for-specific-size(1440px) {
      margin-bottom: 220px;
    }

    @include for-specific-size(1280px) {
      margin-bottom: 200px;
    }

    @include for-specific-size(905px) {
      margin-bottom: 180px;
    }

    @include for-specific-size(375px) {
      margin-bottom: 150px;
    }

    &__gradient {
      border-radius: 0px 0px 60px 60px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));

      @include for-specific-size(1500px) {
        border-radius: 0px 0px 40px 40px;
      }

      @include for-specific-size(1370px) {
        border-radius: 0px 0px 30px 30px;
      }

      @include for-specific-size(375px) {
        border-radius: 0;
      }
    }

    &__video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 60px 60px;

      @include for-specific-size(1500px) {
        border-radius: 0px 0px 40px 40px;
      }

      @include for-specific-size(1370px) {
        border-radius: 0px 0px 30px 30px;
      }

      @include for-specific-size(375px) {
        border-radius: 0;
      }
    }

    &__text-desktop {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 5%;
      color: white;

      @include for-specific-size(905px) {
        display: none;
      }

      &__first-line, &__second-line {
        display: flex;
        column-gap: 50px;

        @include for-specific-size(1500px) {
          column-gap: 40px;
        }

        @include for-specific-size(1370px) {
          column-gap: 35px;
        }

        @include for-specific-size(1280px) {
          column-gap: 30px;
        }

        @include for-specific-size(1120px) {
          column-gap: 20px;
        }
      }

      &__first-line {
        align-items: flex-end;
        vertical-align: bottom;

        small {
          margin-bottom: 33px;

          @include for-specific-size(1500px) {
            margin-bottom: 29px;
          }

          @include for-specific-size(1370px) {
            margin-bottom: 26px;
          }

          @include for-specific-size(1280px) {
            margin-bottom: 21px;
          }

          @include for-specific-size(1120px) {
            margin-bottom: 21px;
          }
        }
      }

      &__second-line {
        padding-left: 75px;

        @include for-specific-size(1500px) {
          padding-left: 65px;
        }

        @include for-specific-size(1370px) {
          padding-left: 55px;
        }

        @include for-specific-size(1280px) {
          padding-left: 45px;
        }

        @include for-specific-size(1120px) {
          padding-left: 35px;
        }

        img {
          @include for-specific-size(1500px) {
            height: 120px;
          }

          @include for-specific-size(1370px) {
            height: 115px;
          }

          @include for-specific-size(1280px) {
            height: 110px;
          }

          @include for-specific-size(1120px) {
            height: 105px;
          }
        }
      }

      b {
        display: inline;
        vertical-align: middle;
        font-style: normal;
        font-family: HelveticaNeueCyr-Bold, sans-serif;
        font-size: 180px;
        line-height: 180px;
        color: #FFFFFF;

        @include for-specific-size(1500px) {
          font-size: 160px;
          line-height: 160px;
        }

        @include for-specific-size(1370px) {
          font-size: 140px;
          line-height: 140px;
        }

        @include for-specific-size(1280px) {
          font-size: 120px;
          line-height: 120px;
        }

        @include for-specific-size(1120px) {
          font-size: 110px;
          line-height: 110px;
        }
      }

      small {
        font-family: HelveticaNeueCyr-Bold, sans-serif;
        font-size: 40px;
        line-height: 40px;
        color: #FFFFFF;

        @include for-specific-size(1500px) {
          font-size: 35px;
          line-height: 35px;
        }

        @include for-specific-size(1370px) {
          font-size: 28px;
          line-height: 28px;
        }
      }
    }

    &__text-mobile {
      display: none;
      position: absolute;

      @include for-specific-size(905px) {
        display: block;
        top: 40%;
        padding: 0 50px;
      }

      @include for-specific-size(746px) {
        display: block;
        top: 40%;
        padding: 0 30px;
      }

      @include for-specific-size(640px) {
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 30%;
        top: unset;
        padding: 0 calc(#{$padding-375} + 3px);
      }

      @include for-specific-size(480px) {
        bottom: 90px;
        top: unset;
        padding: 0 calc(#{$padding-375} + 3px);
      }

      &__first-line {
        b {
          font-family: HelveticaNeueCyr-Bold, sans-serif;
          font-size: 140px;
          line-height: 140px;
          color: #FFFFFF;

          @include for-specific-size(746px) {
            font-size: 120px;
            line-height: 120px;
          }

          @include for-specific-size(640px) {
            font-size: 100px;
            line-height: 100px;
          }
        }
      }

      &__second-line {
        font-size: 90px;
        display: flex;
        align-items: center;

        @include for-specific-size(746px) {
          font-size: 80px;
        }

        @include for-specific-size(640px) {
          font-size: 45px;
        }

        img {
          height: 120px;
          margin-top: -.4em;

          @include for-specific-size(746px) {
            height: 110px;
          }

          @include for-specific-size(640px) {
            height: 82px;
            margin-top: -.6em;
          }
        }

        b {
          font-family: 'HelveticaNeueCyr-Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          line-height: 45px;
          color: #FFFFFF;
        }
      }

      &__third-line {
        p {
          font-family: HelveticaNeueCyr-Bold, sans-serif;
          font-size: 38px;
          line-height: 150%;
          letter-spacing: -0.04em;
          color: #FFFFFF;
          margin-left: auto;
          max-width: 480px;

          @include for-specific-size(746px) {
            font-size: 28px;
            max-width: 360px;
          }

          @include for-specific-size(640px) {
            font-size: 18px;
            line-height: 150%;
            max-width: 220px;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }
    }

    &__start-button {
      position: absolute;
      right: 245px;
      bottom: 0;
      transform: translate(0, 50%);
      text-transform: uppercase;
      display: flex;
      background: #FF5F32;
      border-radius: 40px;
      font-size: 24px;
      line-height: 150%;
      color: #FFFFFF;
      width: 415px;
      padding: 30px 50px;
      text-decoration: none;
      align-items: center;
      justify-content: space-between;
      transition: .3s all;

      @include for-specific-size(905px) {
        border-radius: 30px;
        left: 50%;
        transform: translate(-50%, 35%);
        width: 70%;
        font-size: 28px;
        line-height: 150%;
      }

      @include for-specific-size(640px) {
        transform: translate(-50%, 35%);
        width: 345px;
        padding: 20px 25px;
        font-size: 20px;
        line-height: 150%
      }

      &:hover {
        box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
      }

      p {
        font-family: HelveticaNeueCyr-Bold, sans-serif;
      }
    }
  }

  &__content {
    padding: 0 #{$padding};

    @include for-specific-size(1520px) {
      padding: 0 #{$padding-1520};
    }

    @include for-specific-size(1440px) {
      padding: 0 #{$padding-1440};
    }

    @include for-specific-size(1280px) {
      padding: 0 #{$padding-1280};
    }

    @include for-specific-size(905px) {
      padding: 0 #{$padding-905};
    }

    @include for-specific-size(375px) {
      padding: 0 #{$padding-375};
    }

    &__three-weeks {

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-specific-size(1050px) {
          display: block;
        }

        &__result {
          p, b {
            display: block;
            font-family: HelveticaNeueCyr-Bold, sans-serif;
            color: black;
          }

          p {
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 20px;

            @include for-specific-size(1375px) {
              font-size: 24px;
              line-height: 24px;
              margin-bottom: 10px;
            }
          }

          b {
            font-size: 150px;
            line-height: 149px;

            @include for-specific-size(1375px) {
              font-size: 130px;
              line-height: 120px;
            }

            @include for-specific-size(1280px) {
              font-size: 110px;
              line-height: 110px;
            }

            @include for-specific-size(1140px) {
              font-size: 84px;
              line-height: 84px;
            }

            @include for-specific-size(905px) {
              font-size: 76px;
              line-height: 76px;
              margin-bottom: 40px;
            }

            @include for-specific-size(375px) {
              font-size: 64px;
              line-height: 64px;
              margin-bottom: 30px;
            }
          }
        }

        &__description {
          max-width: 560px;
          font-family: 'HelveticaNeueCyr-Medium', sans-serif;
          font-style: normal;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #19171B;

          @include for-specific-size(1375px) {
            font-size: 18px;
            max-width: 520px;
            line-height: 140%;
          }

          @include for-specific-size(1050px) {
            margin-top: 40px;
            max-width: 720px;
          }

          @include for-specific-size(905px) {
            display: none;
          }
        }
      }

      &__photos__desktop {
        overflow: auto;
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 200px;

        @include for-specific-size(1520px) {
          margin-bottom: 150px;
        }

        @include for-specific-size(1280px) {
          row-gap: 40px;
          column-gap: 80px;
          justify-content: center;
          flex-wrap: wrap;
        }

        @include for-specific-size(1040px) {
          row-gap: 30px;
          column-gap: 50px;
        }

        @include for-specific-size(905px) {
          display: none;
        }

        &__image {
          position: relative;
          width: 320px;

          @include for-specific-size(1840px) {
            width: 280px;
          }

          @include for-specific-size(1680px) {
            width: 240px;
          }

          @include for-specific-size(1400px) {
            width: 220px;
          }

          @include for-specific-size(1320px) {
            width: 200px;
          }

          @include for-specific-size(1280px) {
            width: 240px;
          }

          @include for-specific-size(980px) {
            width: 200px;
          }

          img {
            width: 100%;
            object-fit: scale-down;
          }

          span {
            top: 20px;
            left: 20px;
            font-family: HelveticaNeueCyr-Medium, sans-serif;
            position: absolute;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            background: #FF5F32;
            border-radius: 15px;
            padding: 10px 12px 8px 12px;

            @include for-specific-size(1320px) {
              font-size: 18px;
              line-height: 18px;
              padding: 6px 10px;
              border-radius: 12px;
            }

            @include for-specific-size(1280px) {
              font-size: 20px;
              line-height: 20px;
              padding: 8px 12px;
              border-radius: 15px;
            }

            @include for-specific-size(980px) {
              font-size: 18px;
              line-height: 18px;
              padding: 6px 10px;
              border-radius: 12px;
            }
          }
        }
      }

      &__photos__mobile {
        column-gap: 25px;
        display: none;
        justify-content: space-between;

        @include for-specific-size(905px) {
          column-gap: 50px;
          display: flex;
        }

        @include for-specific-size(640px) {
          column-gap: 40px;
        }

        @include for-specific-size(375px) {
          column-gap: 25px;
        }

        &__left {
          flex: 1 1 0;
        }

        &__right {
          flex: 1 1 0;

          &__description {
            font-family: HelveticaNeueCyr-Medium, sans-serif;
            font-size: 28px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #19171B;
            margin-bottom: 20px;

            @include for-specific-size(740px) {
              font-size: 24px;
            }

            @include for-specific-size(640px) {
              margin-bottom: 22px;
            }

            @include for-specific-size(520px) {
              font-size: 16px;
            }

          }
        }

        &__image {
          position: relative;
          margin-bottom: 50px;

          @include for-specific-size(640px) {
            margin-bottom: 40px;
          }

          @include for-specific-size(520px) {
            margin-bottom: 20px;
          }

          span {
            font-family: HelveticaNeueCyr-Medium, sans-serif;
            position: absolute;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            background: #FF5F32;
            left: 20px;
            top: 20px;
            font-size: 24px;
            line-height: 24px;
            padding: 10px 15px;
            border-radius: 20px;

            @include for-specific-size(520px) {
              left: 10px;
              top: 10px;
              font-size: 16px;
              line-height: 16px;
              padding: 10px;
              border-radius: 10px;
            }
          }

          img {
            width: 100%;
            object-fit: cover;
            border-radius: 30px;

            @include for-specific-size(740px) {
              height: 320px;
            }

            @include for-specific-size(640px) {
              height: 280px;
            }

            @include for-specific-size(520px) {
              height: 240px;
            }

            @include for-specific-size(375px) {
              height: 160px;
              width: 160px;
              object-fit: cover;
              border-radius: 20px;
            }
          }
        }
      }
    }

    &__program {
      &__header {
        display: flex;
        align-items: center;

        @include for-specific-size(905px) {
          display: block;
        }

        p, b {
          display: block;
        }

        b {
          font-family: HelveticaNeueCyr-Bold, sans-serif;
          font-size: 96px;
          line-height: 130%;
          color: #000000;
          margin-right: 170px;

          @include for-specific-size(1300px) {
            font-size: 84px;
          }

          @include for-specific-size(1160px) {
            font-size: 76px;
          }

          @include for-specific-size(1100px) {
            font-size: 62px;
          }

          @include for-specific-size(975px) {
            font-size: 48px;
          }

          @include for-specific-size(905px) {
            font-size: 76px;
            line-height: 76px;
            margin-top: 80px;
          }

          @include for-specific-size(850px) {
            font-size: 64px;
            line-height: 64px;
          }

          @include for-specific-size(740px) {
            font-size: 54px;
            line-height: 54px;;
          }

          @include for-specific-size(660px) {
            font-size: 48px;
            line-height: 48px;
          }

          @include for-specific-size(615px) {
            font-size: 36px;
            line-height: 130%;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }

        p {
          font-family: HelveticaNeueCyr-Medium, sans-serif;
          font-size: 20px;
          line-height: 150%;
          color: #000000;
          max-width: 205px;

          @include for-specific-size(905px) {
            margin-top: 20px;
            max-width: 100%;
          }

          @include for-specific-size(660px) {
            max-width: 205px;
          }
        }
      }

      &__photos {
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 130px;
        margin-left: -#{$padding};
        column-gap: 50px;
        width: calc(100% + 2 * #{$padding});
        padding-bottom: 80px;

        @include for-specific-size(1520px) {
          margin-left: -#{$padding-1520};
          width: calc(100% + 2 * #{$padding-1520});
        }

        @include for-specific-size(1440px) {
          margin-left: -#{$padding-1440};
          width: calc(100% + 2 * #{$padding-1440});
        }

        @include for-specific-size(1280px) {
          margin-left: -#{$padding-1280};
          width: calc(100% + 2 * #{$padding-1280});
        }

        @include for-specific-size(905px) {
          margin-left: -#{$padding-905};
          width: calc(100% + 2 * #{$padding-905});
        }

        @include for-specific-size(390px) {
          margin-top: 30px;
          column-gap: 10px;
          padding-bottom: 20px;
          margin-left: -#{$padding-375};
          width: calc(100% + 2 * #{$padding-375});
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 50px;
        }


        img {
          height: 410px;
          border-radius: 20px;
          object-fit: scale-down;

          &:first-child {
            animation: moveLeft-disappear 3s linear forwards;
          }

          &:last-child {
            animation: moveLeft-appear 3s linear forwards;
          }

          @include for-specific-size(375px) {
            height: 215px;
          }
        }
      }
    }

    &__difference {
      margin-top: 100px;
      background: #FFE9D6;
      border-radius: 50px;
      padding: 40px 200px 40px 80px;
      display: flex;
      margin-bottom: 80px;

      @include for-specific-size(1500px) {
        padding: 40px 150px 40px 80px;
      }

      @include for-specific-size(1320px) {
        padding: 40px 50px 40px 80px;
      }

      @include for-specific-size(740px) {
        margin-top: 50px;
        border-radius: 30px;
        padding: 35px 20px;
        display: block;
        margin-bottom: 50px;
      }

      &__description {
        margin-top: 40px;

        @include for-specific-size(375px) {
          margin-top: 0;
        }

        b {
          font-family: HelveticaNeueCyr-Bold, sans-serif;
          font-size: 32px;
          line-height: 32px;
          letter-spacing: -0.04em;
          color: #19171B;
          display: block;
          margin-bottom: 20px;

          @include for-specific-size(375px) {
            font-size: 20px;
            line-height: 20px;
          }
        }

        p {
          font-family: 'HelveticaNeueCyr-Medium', sans-serif;
          font-style: normal;
          font-size: 20px;
          line-height: 130%;
          color: #19171B;
          margin-bottom: 40px;
          max-width: 362px;

          @include for-specific-size(375px) {
            font-size: 16px;
            line-height: 130%;
            max-width: 275px;
          }
        }

        strong {
          display: block;
          font-family: 'HelveticaNeueCyr-Bold', sans-serif;
          font-style: normal;
          font-size: 96px;
          line-height: 96px;
          color: #19171B;
          max-width: 777px;

          @include for-specific-size(1640px) {
            font-size: 76px;
            line-height: 76px;
            max-width: 640px;
          }

          @include for-specific-size(1320px) {
            font-size: 64px;
            line-height: 64px;
            max-width: 540px;
          }

          @include for-specific-size(1024px) {
            font-size: 52px;
            line-height: 52px;
            max-width: 450px;
          }

          @include for-specific-size(740px) {
            max-width: 100%;
          }

          @include for-specific-size(375px) {
            font-size: 36px;
            line-height: 100%;
          }

          span {
            box-sizing: border-box;
            vertical-align: middle;
            padding: 7px 0 0 0;
            margin-right: 7px;
            position: relative;
            display: inline-block;
            color: white;
            width: 230px;
            height: 100px;
            text-align: center;
            background: #FF5F32;
            border-radius: 17px;
            transform: rotate(-3deg);
            margin-top: -5px;
            line-height: 95px;

            @include for-specific-size(1640px) {
              width: 180px;
              height: 80px;
              line-height: 75px;
            }

            @include for-specific-size(1320px) {
              width: 150px;
              height: 70px;
              line-height: 65px;
            }

            @include for-specific-size(1024px) {
              width: 130px;
              height: 60px;
              line-height: 55px;
            }

            @include for-specific-size(740px) {
              width: 130px;
              height: 60px;
              line-height: 55px;
              padding: 3px 0 0 0;
            }

            @include for-specific-size(375px) {
              border-radius: 10px;
              width: 90px;
              height: 45px;
              line-height: 42px;
            }
          }
        }
      }

      img {
        width: 370px;
        margin-left: auto;

        @include for-specific-size(1500px) {
          width: 330px;
        }

        @include for-specific-size(1100px) {
          width: 280px;
        }

        @include for-specific-size(1024px) {
          padding-left: 40px;
          object-fit: scale-down;
          width: 260px;
        }

        @include for-specific-size(740px) {
          padding-left: 0;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 50px;
          width: 375px;
        }

        @include for-specific-size(540px) {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
        }

        @include for-specific-size(375px) {
          padding-left: 0;
          padding-right: 0;
          width: 225px;
        }
      }
    }

    &__performance {
      background: #FFFFFF;
      border-radius: 50px;
      padding: 40px 80px 40px 150px;
      display: flex;
      margin-bottom: 80px;

      @include for-specific-size(1560px) {
        padding: 40px 60px 40px 100px;
      }

      @include for-specific-size(905px) {
        padding: 40px 60px;
      }

      @include for-specific-size(740px) {
        margin-top: 50px;
        border-radius: 30px;
        padding: 35px 20px;
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 50px;
      }

      img {
        width: 370px;
        margin-right: auto;
        object-fit: cover;

        @include for-specific-size(1700px) {
          width: 330px;
        }

        @include for-specific-size(1456px) {
          width: 280px;
        }

        @include for-specific-size(1235px) {
          padding-right: 40px;
          object-fit: scale-down;
          width: 260px;
        }

        @include for-specific-size(740px) {
          padding-right: 0;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 50px;
          width: 375px;
        }

        @include for-specific-size(540px) {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
        }

        @include for-specific-size(375px) {
          padding-left: 0;
          padding-right: 0;
          width: 225px;
        }
      }

      b {
        margin-top: auto;
        margin-bottom: auto;
        font-style: normal;
        font-family: HelveticaNeueCyr-Bold, sans-serif;
        font-size: 64px;
        line-height: 140%;
        color: #000000;
        max-width: 900px;

        @include for-specific-size(1780px) {
          font-size: 52px;
          max-width: 800px;
        }

        @include for-specific-size(1640px) {
          font-size: 48px;
          max-width: 750px;
        }

        @include for-specific-size(1365px) {
          font-size: 42px;
          max-width: 650px;
        }

        @include for-specific-size(1050px) {
          font-size: 36px;
        }

        @include for-specific-size(905px) {
          font-size: 32px;
        }

        @include for-specific-size(740px) {
          max-width: 100%;
        }

        @include for-specific-size(375px) {
          font-size: 32px;
          margin-bottom: 50px;
          max-width: 305px;
        }

        span {
          color: white;
          background: #FF5F32;
          border-radius: 17px;
          padding: 10px 15px 6px 15px;

          @include for-specific-size(620px) {
            border-radius: 10px;
            padding: 5px 10px 3px 10px;
          }
        }
      }
    }

    &__tracker {
      background: #FFE9D6;
      border-radius: 50px;
      display: flex;
      padding: 40px 200px 40px 80px;

      @include for-specific-size(1500px) {
        padding: 40px 150px 40px 80px;
      }

      @include for-specific-size(1320px) {
        padding: 40px 50px 40px 80px;
      }

      @include for-specific-size(740px) {
        margin-top: 50px;
        border-radius: 30px;
        padding: 35px 20px;
        display: block;
        margin-bottom: 50px;
      }

      img {
        margin-left: auto;
        width: 370px;

        @include for-specific-size(1500px) {
          width: 330px;
        }

        @include for-specific-size(1100px) {
          width: 280px;
        }

        @include for-specific-size(1024px) {
          padding-left: 40px;
          object-fit: scale-down;
          width: 260px;
        }

        @include for-specific-size(740px) {
          padding-left: 0;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 50px;
          width: 375px;
        }

        @include for-specific-size(540px) {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
        }

        @include for-specific-size(375px) {
          padding-left: 0;
          padding-right: 0;
          width: 225px;
        }
      }

      b {
        margin-top: auto;
        margin-bottom: auto;
        font-family: 'HelveticaNeueCyr-Bold', sans-serif;
        font-style: normal;
        font-size: 64px;
        line-height: 140%;
        color: #19171B;
        max-width: 930px;

        @include for-specific-size(1780px) {
          font-size: 52px;
          max-width: 800px;
        }

        @include for-specific-size(1640px) {
          font-size: 48px;
          max-width: 750px;
        }

        @include for-specific-size(1365px) {
          font-size: 42px;
          max-width: 650px;
        }

        @include for-specific-size(1050px) {
          font-size: 36px;
        }

        @include for-specific-size(905px) {
          font-size: 32px;
        }

        @include for-specific-size(740px) {
          max-width: 100%;
        }

        @include for-specific-size(375px) {
          font-size: 32px;
          margin-bottom: 50px;
          max-width: 305px;
        }

        span {
          white-space: nowrap;
          color: white;
          background: #FF5F32;
          border-radius: 17px;
          padding: 10px 15px 6px 15px;

          @include for-specific-size(620px) {
            border-radius: 10px;
            padding: 5px 10px 3px 10px;
          }
        }
      }
    }

    &__download {
      display: flex;
      column-gap: 30px;
      margin: 30px auto 100px;
      justify-content: center;

      @include for-specific-size(700px) {
        display: block;
      }

      @include for-specific-size(375px) {
        margin-bottom: 40px;
      }

      a {
        max-width: 320px;
        background: #FF5F32;
        border-radius: 30px;
        color: white;
        display: flex;
        padding: 30px 30px 30px 30px;
        align-items: center;

        @include for-specific-size(700px) {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 40px;
        }

        @include for-specific-size(375px) {
          margin-bottom: 20px;
          border-radius: 20px;
          max-width: 270px;
          padding: 20px 35px 20px 30px;
        }

        svg {
          margin-right: 23px;
          height: 40px;

          @include for-specific-size(375px) {
            height: 20px;
            margin-right: 20px;
          }
        }

        p {
          font-size: 24px;
          line-height: 24px;
          font-family: HelveticaNeueCyr-Bold, sans-serif;
          padding-left: 23px;
          padding-top: 4px;
          padding-bottom: 4px;
          border-left: 1px solid white;

          @include for-specific-size(375px) {
            font-size: 20px;
            line-height: 20px;
            white-space: nowrap;
          }
        }
      }
    }

    &__programs {
      padding-top: 100px;
      margin-bottom: 200px;

      @include for-specific-size(390px) {
        padding-top: 40px;
        margin-bottom: 100px;
      }

      &__header {
        b, p {
          display: block;
        }

        b {
          font-family: 'HelveticaNeueCyr-Bold', sans-serif;
          font-style: normal;
          font-size: 96px;
          line-height: 130%;
          color: #000000;
          margin-bottom: 20px;

          @include for-specific-size(1024px) {
            font-size: 84px;
          }

          @include for-specific-size(655px) {
            font-size: 72px;
          }

          @include for-specific-size(540px) {
            font-size: 62px;
          }

          @include for-specific-size(375px) {
            font-size: 36px;
            line-height: 36px;
          }
        }

        p {
          max-width: 560px;
          margin-bottom: 50px;
          font-family: 'HelveticaNeueCyr-Medium', sans-serif;
          font-style: normal;
          font-size: 20px;
          line-height: 130%;
          color: #000000;

          @include for-specific-size(375px) {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 30px;
          }
        }
      }

      &__photos {
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        margin-bottom: 130px;
        margin-left: -#{$padding};
        column-gap: 50px;
        width: calc(100% + 2 * #{$padding});
        padding-bottom: 80px;
        overflow: auto;

        @include for-specific-size(1520px) {
          margin-left: -#{$padding-1520};
          width: calc(100% + 2 * #{$padding-1520});
        }

        @include for-specific-size(1440px) {
          margin-left: -#{$padding-1440};
          width: calc(100% + 2 * #{$padding-1440});
        }

        @include for-specific-size(1280px) {
          margin-left: -#{$padding-1280};
          width: calc(100% + 2 * #{$padding-1280});
        }

        @include for-specific-size(905px) {
          margin-left: -#{$padding-905};
          width: calc(100% + 2 * #{$padding-905});
        }

        @include for-specific-size(510px) {
          margin-right: 0;
          margin-left: -#{$padding-375};
          width: calc(100% + 2 * #{$padding-375});
          margin-top: 30px;
          column-gap: 10px;
          padding-bottom: 20px;
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 50px;
        }

        &__photo {
          width: 512px;
          flex: 0 0 512px;
          height: 350px;
          border-radius: 20px;
          overflow: hidden;
          position: relative;

          @include for-specific-size(1440px) {
            width: 480px;
            flex: 0 0 480px;
            height: 320px;
          }

          @include for-specific-size(1280px) {
            width: 420px;
            flex: 0 0 420px;
            height: 300px;
          }

          @include for-specific-size(510px) {
            width: 380px;
            flex: 0 0 380px;
            height: 280px;
          }

          @include for-specific-size(470px) {
            width: 330px;
            flex: 0 0 330px;
            height: 250px;
          }

          span {
            top: 20px;
            left: 20px;
            font-family: HelveticaNeueCyr-Medium, sans-serif;
            position: absolute;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            background: #FF5F32;
            border-radius: 15px;
            padding: 10px 12px 8px 12px;

            @include for-specific-size(1280px) {
              padding: 9px 11px 7px 11px;
              font-size: 18px;
              line-height: 18px;
              border-radius: 14px;
            }

            @include for-specific-size(510px) {
              font-size: 16px;
              line-height: 16px;
              padding: 8px 10px 6px 10px;
              border-radius: 12px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }

          @include for-specific-size(375px) {

          }
        }
      }
    }

    &__coaches {
      display: flex;
      margin-bottom: 250px;

      @include for-specific-size(1180px) {
        display: block;
        margin-bottom: 100px;
      }

      &__description {

        p, b, a, small {
          display: block;
        }

        a {
          text-decoration: none;
          background: #EB5E28;
          display: flex;
          padding: 25px 30px 25px 30px;
          border-radius: 30px;
          max-width: 270px;
          max-height: 110px;
          align-items: center;

          @include for-specific-size(1180px) {
            margin-left: auto;
            max-width: 370px;
            border-radius: 20px;
          }

          @include for-specific-size(580px) {
            margin-left: unset;
            padding: 20px 25px;
            width: 100%;
            max-width: unset;
            border-radius: 20px;
          }

          p {
            font-family: 'HelveticaNeueCyr-Bold', sans-serif;
            font-style: normal;
            font-size: 20px;
            line-height: 150%;
            color: #FFFFFF;

            @include for-specific-size(1180px) {
              br {
                display: none;
              }
            }

            @include for-specific-size(580px) {
              font-size: 20px;
              line-height: 20px;
            }
          }

          svg {
            margin-left: auto;

            @include for-specific-size(580px) {
              width: 20px;
            }
          }
        }

        small {
          margin-top: 20px;
          font-family: 'HelveticaNeueCyr-Light', sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: #000000;
          padding-left: 20px;

          @include for-specific-size(1180px) {
            margin-left: auto;
            text-align: right;
          }

          @include for-specific-size(580px) {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            font-size: 16px;
            line-height: 16px;
            margin-top: 15px;
          }
        }

        &__header {
          padding-top: 40px;

          @include for-specific-size(1860px) {
            padding-top: 0;
          }

          b {
            max-width: 630px;
            font-family: 'HelveticaNeueCyr-Bold', sans-serif;
            font-style: normal;
            font-size: 96px;
            line-height: 130%;
            color: #000000;
            margin-bottom: 20px;

            @include for-specific-size(1860px) {
              font-size: 92px;
            }

            @include for-specific-size(1760px) {
              font-size: 84px;
            }

            @include for-specific-size(1700px) {
              font-size: 80px;
            }

            @include for-specific-size(1440px) {
              font-size: 64px;
              max-width: 550px;
            }

            @include for-specific-size(1350px) {
              max-width: 450px;
              font-size: 52px;
            }

            @include for-specific-size(580px) {
              font-size: 36px;
              line-height: 130%;
              max-width: 240px;
              margin-bottom: 30px;
            }
          }

          p {
            font-family: HelveticaNeueCyr-Medium, sans-serif;
            font-size: 20px;
            line-height: 130%;
            color: #000000;
            max-width: 560px;
            margin-bottom: 120px;

            @include for-specific-size(1700px) {
              margin-bottom: 40px;
            }

            @include for-specific-size(1180px) {
              margin-left: auto;
              text-align: right;
            }

            @include for-specific-size(580px) {
              margin-left: unset;
              text-align: left;
              font-size: 16px;
              line-height: 150%;
              margin-bottom: 30px;
            }
          }

          img {
            display: none;

            @include for-specific-size(1180px) {
              display: block;
              width: 100%;
              height: 440px;
              object-fit: cover;
              margin-bottom: 20px;
              border-radius: 30px;
            }

            @include for-specific-size(375px) {
              display: block;
              width: 100%;
              height: 230px;
              object-fit: scale-down;
              margin-bottom: 20px;
            }
          }
        }
      }

      &__image {
        margin-left: auto;
        object-fit: cover;
        border-radius: 30px;

        @include for-specific-size(1860px) {
          width: 920px;
        }

        @include for-specific-size(1760px) {
          width: 820px;
          height: 560px;
        }

        @include for-specific-size(1700px) {
          width: 740px;
          height: 480px;
        }

        @include for-specific-size(1500px) {
          width: 640px;
          height: 480px;
        }

        @include for-specific-size(1440px) {
          width: 580px;
          height: 480px;
        }

        @include for-specific-size(1350px) {
          width: 480px;
          height: 360px;
        }

        @include for-specific-size(1180px) {
          display: none;
        }
      }
    }

    &__reviews {
      strong {
        display: block;
        max-width: 1140px;
        font-family: 'HelveticaNeueCyr-Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 96px;
        line-height: 130%;
        color: #000000;
        margin-bottom: 50px;

        @include for-specific-size(1470px) {
          font-size: 84px;
          max-width: 1000px;
        }

        @include for-specific-size(1111px) {
          font-size: 74px;
          max-width: 800px;
        }

        @include for-specific-size(870px) {
          font-size: 54px;
          max-width: 550px;
        }

        @include for-specific-size(510px) {
          font-size: 36px;
          line-height: 130%;
        }
      }

      &__content {
        margin-left: -#{$padding};
        width: calc(100% + calc(2 * #{$padding}));
        display: flex;
        overflow: auto;
        margin-bottom: 200px;
        padding-bottom: 80px;
        align-items: flex-start;
        column-gap: 20px;
        padding-left: 20px;
        padding-right: 20px;

        @include for-specific-size(1520px) {
          margin-left: -#{$padding-1520};
          width: calc(100% + 2 * #{$padding-1520});
          padding-left: $padding-1520;
          padding-right: $padding-1520;
        }

        @include for-specific-size(1520px) {
          margin-left: -#{$padding-1520};
          width: calc(100% + 2 * #{$padding-1520});
          padding-left: $padding-1520;
          padding-right: $padding-1520;
        }

        @include for-specific-size(1440px) {
          margin-left: -#{$padding-1440};
          width: calc(100% + 2 * #{$padding-1440});
          padding-left: $padding-1440;
          padding-right: $padding-1440;
        }

        @include for-specific-size(1280px) {
          margin-left: -#{$padding-1280};
          width: calc(100% + 2 * #{$padding-1280});
          padding-left: $padding-1280;
          padding-right: $padding-1280;
        }

        @include for-specific-size(905px) {
          margin-left: -#{$padding-905};
          width: calc(100% + 2 * #{$padding-905});
          padding-left: $padding-905;
          padding-right: $padding-905;
        }

        @include for-specific-size(390px) {
          margin-left: -#{$padding-375};
          width: calc(100% + calc(2 * #{$padding-375}));
          padding-left: $padding-375;
          padding-right: $padding-375;
          padding-bottom: 20px;
          margin-bottom: 100px;
        }

        &__tile {
          margin-left: auto;
          margin-right: auto;
          flex: 0 0 415px;
          background: #FFFFFF;
          border-radius: 30px;
          padding: 30px 25px 30px 25px;

          @include for-specific-size(375px) {
            padding: 20px 10px 30px 20px;
            flex: 0 0 375px;
          }

          p, b {
            display: block;
          }

          b {
            font-family: HelveticaNeueCyr-Bold, sans-serif;
            font-size: 20px;
            line-height: 20px;
            color: #000000;
            margin-bottom: 10px;
          }

          &__header {
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 30px;

            @include for-specific-size(375px) {
              margin-bottom: 15px;
            }

            p {
              font-family: 'HelveticaNeueCyr-Medium', sans-serif;
              font-size: 16px;
              line-height: 16px;
              color: #000000;
              margin-bottom: 20px;

              @include for-specific-size(375px) {
                margin-bottom: 15px;
              }
            }
          }

          &__content {
            p {
              font-family: 'HelveticaNeueCyr-Light', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: #000000;
            }
          }
        }
      }
    }
  }

  &__outro-banner {
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    max-height: 920px;
    min-height: 840px;
    position: relative;
    padding-top: 80px;
    padding-left: $padding;
    background: url("../../assets/images/Landing/footer-block/bg.png") center center no-repeat;
    background-size: cover;
    border-radius: 60px 60px 0px 0px;

    &__gradient {
      border-radius: 60px 60px 0px 0px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));

      @include for-specific-size(1440px) {
        border-radius: 40px 40px 0px 0px;
      }

      @include for-specific-size(905px) {
        border-radius: 20px 20px 0px 0px;
      }

      @include for-specific-size(700px) {
        border-radius: 0;
      }
    }

    @include for-specific-size(1520px) {
      padding-left: $padding-1520;
    }

    @include for-specific-size(1440px) {
      padding-left: $padding-1440;
      border-radius: 40px 40px 0px 0px;
    }

    @include for-specific-size(1280px) {
      padding-left: $padding-1280;
      background-position-x: -200px;
    }

    @include for-specific-size(905px) {
      padding-left: $padding-905;
      background-position-x: -400px;
      border-radius: 20px 20px 0px 0px;
    }

    @include for-specific-size(700px) {
      padding-top: 50px;
      height: 675px;
      padding-left: $padding-375;
      background-position-x: -300px;
      border-radius: 0;
    }

    @include for-specific-size(520px) {
      background-position-x: -450px;
    }

    &__text {
      position: relative;

      &__header {
        p, b {
          display: block;
        }

        p {
          font-family: 'HelveticaNeueCyr-Bold', sans-serif;
          margin-bottom: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 130%;
          color: #19171B;

          @include for-specific-size(470px) {
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 10px;
          }
        }

        b {
          font-family: 'HelveticaNeueCyr-Bold', sans-serif;
          max-width: 585px;
          font-style: normal;
          font-weight: 700;
          font-size: 96px;
          line-height: 130%;
          color: #FFFFFF;

          @include for-specific-size(905px) {
            font-size: 74px;
            max-width: 500px;
          }

          @include for-specific-size(470px) {
            font-size: 48px;
            line-height: 130%;
            max-width: 300px;
          }
        }
      }

      &__start-button {
        font-family: HelveticaNeueCyr-Bold, sans-serif !important;
        margin-top: 75px;
        background: #FF5F32;
        border-radius: 30px;
        color: white;
        width: 270px;
        height: 110px;
        display: flex;
        padding: 25px 30px;
        text-decoration: none;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        align-items: center;

        @include for-specific-size(700px) {
          margin-top: 100px;
          padding: 20px 25px;
          width: 270px;
          br {
            display: block !important;
          }
        }

        svg {
          margin-left: auto;
        }
      }
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 0;
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 20px #{$padding};

      @include for-specific-size(1520px) {
        padding: 15px $padding-1520;
      }

      @include for-specific-size(1440px) {
        padding: 15px $padding-1440;
      }

      @include for-specific-size(1280px) {
        padding: 15px $padding-1280;
      }

      @include for-specific-size(905px) {
        padding: 15px $padding-905;
      }

      @include for-specific-size(700px) {
        padding: 15px #{$padding-375};
        display: block;
      }

      a {
        text-decoration: none;
        font-family: HelveticaNeueCyr-Bold, sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 145%;
        color: #19171B;

        @include for-specific-size(700px) {
          font-size: 14px;
          line-height: 145%;
        }
      }

      &__links {
        display: flex;
        column-gap: 80px;

        @include for-specific-size(700px) {
          column-gap: unset;
          justify-content: space-between;
        }
      }

      &__social {
        margin-left: auto;
        display: flex;
        column-gap: 35px;
        align-items: center;

        @include for-specific-size(700px) {
          display: none;
        }
      }
    }
  }
}

.move_left {
  animation: moveLeft 3s infinite linear;

  @include for-specific-size(1520px) {
    animation: moveLeft-1520 3s infinite linear;
  }

  @include for-specific-size(1440px) {
    animation: moveLeft-1440 3s infinite linear;
  }

  @include for-specific-size(1280px) {
    animation: moveLeft-1280 3s infinite linear;
  }

  @include for-specific-size(905px) {
    animation: moveLeft-905 3s infinite linear;
  }

  @include for-specific-size(375px) {
    animation: moveLeft-375 3s infinite linear;
  }
}

.landing__gif__wrapper {
  margin-top: auto;
  margin-bottom: auto;
  background: url('../../assets/images/Landing/i-mockup.png') center center no-repeat;
  background-size: contain;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 376px;
  height: 753px;

  span {
    position: absolute;
    width: 190px;
    height: 33px;
    display: block;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    background: url('../../assets/images/Landing/brow.png') center center no-repeat;
  }

  img {
    width: 325px;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    border-radius: 30px;
  }
}

@keyframes moveLeft {
  to {
    margin-left: -256px - $padding - 50px;
  }
}

@keyframes moveLeft-1520 {
  to {
    margin-left: -256px - $padding-1520 - 50px;
  }
}

@keyframes moveLeft-1440 {
  to {
    margin-left: -256px - $padding-1440 - 50px;
  }
}

@keyframes moveLeft-1280 {
  to {
    margin-left: -256px - $padding-1280 - 50px;
  }
}

@keyframes moveLeft-905 {
  to {
    margin-left: -256px - $padding-905 - 50px;
  }
}

@keyframes moveLeft-375 {
  to {
    margin-left: -130px - $padding-375 - 15px;
  }
}

@keyframes moveLeft-appear {
  from {
    transform: translate(120%, 0);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes moveLeft-disappear {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(-120, 0);
    opacity: 0;
  }
}
