@import '../../../assets/styles/variables';

.custom-input {
  flex-grow: 1;
  padding: 14px 18px 14px 18px;
  background: $color-text-white;
  border: 1px solid $color-border-primary;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $color-text-light;
  }

  &-contact {
    outline: none;
    border: none;
    border-bottom: 1px solid $color-border-primary;
    border-radius: 0;
    padding: 0;
  }

  &__container {
    margin: 0 0 15px 0;
    position: relative;
  }

  &__single {
    &-item {
      flex-grow: 1;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    &__errors {
      position: absolute;
      color: $color-text-red;
      margin-left: 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &:focus {
    border: 1px solid $color-border-quaternary;
    box-sizing: border-box;
    border-radius: 10px;
  }

  &__icon {
    position: absolute;
    left: 12px;
    width: 20px;
    height: 20px;
  }
}

.errors {
  position: absolute;
  color: $color-text-red;
  font-size: 12px;
  line-height: 16px;
  bottom: -15px;
  left: 12px;
}

// removing input background colour for chrome autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $color-text-white inset !important;
}

.contact-us__form .custom-input:focus {
  border-bottom: 1px solid #FE6D02 !important;
  border-radius: 0;
}

.contact-us__form .custom-input {
  padding: 0 0 0 !important;
}

.about-me__form .custom-input {
  padding: 0 0 0 !important;

}

.about-me__form :focus {
  border-bottom: 1px solid #FE6D02 !important;
  border-radius: 0;
}