@import '../../assets/styles/variables';

.cart-raining {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 40px 90px;

  @include for-specific-size(1400px) {
    padding: 20px 40px;
  }

  &:after {
    content: "";
    flex: 0 1 30%;
  }

  @include for-specific-size(1310px) {
    &:after {
      flex: 0 1 32%;
    }
  }

  @include for-specific-size(1100px) {
    &:after {
      flex: 0 1 48%;
    }
  }

  @include for-specific-size(770px) {
    &:after {
      flex: 0 1 100%;
    }
  }

  &__no-found {
    font-size: 24px;
    font-family: 'SFProDisplay-Semibold', sans-serif;
  }

  &__item {
    border-radius: 20px 20px 2px 2px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: 533ms;
    height: 270px;
    flex: 0 1 30%;
    position: relative;
    display: flex;
    flex-direction: column;

    @include for-specific-size(1440px) {
      height: 220px;
      margin-bottom: 20px;
    }

    @include for-specific-size(1310px) {
      flex: 0 1 32%;
    }

    @include for-specific-size(1100px) {
      flex: 0 1 48%;
      margin-bottom: 15px;
    }

    @include for-specific-size(770px) {
      flex: 0 1 100%;
      margin-bottom: 10px;
    }


    &-play {
      width: 39px;
      height: 39px;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 533ms;
    }

    &_name {
      color: #000 !important;
      font-family: 'SFProDisplay-Regular', sans-serif;
    }

    &-placeholder {
      flex: 1 1 100%;
      overflow: hidden;
      position: relative;
    }

    &-logo {
      height: 100%;
      width: 100%;
      border-radius: 20px;
      object-fit: cover;
      object-position: center center;
    }

    &_footer {
      flex: 0 0 30px;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;

      &-time {
        display: flex;
        min-width: 80px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;

          img {
            height: 13.33px;
            width: 13.33px;
          }
        }
      }

      p {
        margin: 2px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #8F8F8F
      }
    }

    &__save {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 24px;
      height: 24px;
      z-index: 1;
      pointer-events: all;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: #EB5E28;
          fill: #EB5E28;
          fill-opacity: 0;
        }
      }
    }

    &:hover {
      .cart-raining__item-play {
        width: 50px;
        height: 50px;
      }
    }
  }
}

