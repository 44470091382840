.pereparing-plan {
  display: flex;
  flex-direction: column;
  align-items: center;

div {
    span {
        font-size: 14px !important;
    }
}

  &__description {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: rgba(18, 18, 29, 0.6);
    margin-bottom: 5%;
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: black;
    margin: 50px auto;
  }
}
