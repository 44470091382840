@import '../../../../assets/styles/variables';

.height-weight-question {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  column-gap: 20px;
  width: 350px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;


  @include for-size(tablet-phone) {
    row-gap: 20px;
  }

  @include for-specific-size(495px) {
    width: calc( 100% + 28px);
    margin-left: -14px;
  }


  &-label {
    width: 150px;
    text-align: center;

    p {
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      color: #B9B9B9;
    }

    &:last-child {
      margin: 34px auto auto;
      @include for-size(tablet-phone) {
        margin: 15px auto auto
      }
    }

    &:nth-child(2) {
      margin-top: 24px;

      &:before {
        transform: translatey(-50%);
      }
    }

    position: relative;

    &:before {
      content: attr(data-parent);
      display: flex;
      position: absolute;
      text-transform: lowercase;

      right: 25px;
      top: 50%;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      z-index: 4;
      color: rgba(18, 18, 29, 0.4);
    }
  }

  &-input {
    width: 100%;
    height: 74px;
    border-radius: 50px;
    box-sizing: border-box;
    background: #F2F2F2;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;

    &:focus {
      background: #FFFFFF;
      border: 2px solid #FE6D02;
      box-sizing: border-box;
      border-radius: 60px;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent
    }

    &:focus::-moz-placeholder {
      color: transparent
    }

    &:focus:-moz-placeholder {
      color: transparent
    }

    &:focus:-ms-input-placeholder {
      color: transparent
    }

    border: none;
    color: black;
    position: relative;

    @include for-size(phone) {
      height: 75px;
    }

    ::-webkit-input-placeholder {
      text-align: center;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
    }

    ::-moz-placeholder {
      text-align: center;
    }

    :-ms-input-placeholder {
      text-align: center;
    }

    &:focus-visible {
      outline: none;
    }
  }
}
