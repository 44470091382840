.landing__contact-us {
  background: white;
  margin: auto;
  border-radius: 50px;
  width: 50%;
  box-sizing: border-box;
  display: flex;

  .contact-us {
    box-sizing: border-box;
    flex: 1 1 auto;
    max-width: 100%;

    button {
      margin-bottom: 0;
      margin-left: auto;
    }
  }
}
