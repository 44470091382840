@import '../../../../assets/styles/variables';

.about-me {
    width: 60%;
    padding: 60px 40px;
    max-width: 442px;
    @include for-size(tablet-phone) {
      max-width: 100%;
      width: 100vw;
      margin: 0;
      padding: 30px;
      height: 100vh;
      background: #fff;
      position: fixed;
      overflow: auto;
      top: 0;
      left: 0;
      z-index: 100;
      box-sizing: border-box;
    }

    &__content {
      margin: 0 0 30px 0;

      &-back {
        cursor: pointer !important;
        display: flex;
        margin-bottom: 15px;
      }
    }

    &__form {
      button[type = 'submit'] {
        &:focus {
          border-radius: 50px;
        }
      }
      fieldset {
        margin: 0 0 15px 0;
        width: 100%;
        border: none;
        padding: 0;
        span {
          display: block;
          color: #878787;
        }
      }

      .custom-input {
        font-style: normal;
        font-weight: 400;
        font-size: 16px !important;
      }

      select {
        transform: translate(-3px, 0);
        padding: 0;
        position: relative;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #f1f0f0;
        background: none;
        outline: none;
        width: 100%;
      }

      .custom-input, select {
        padding: 10px 0;
        color:  black;
        font-size: 18px;
        line-height: 24px;

        &:focus {
          border: none;
        }
      }

      .custom-input__label {
        color: rgba(18, 18, 29, 0.3);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      .custom-button {
        margin: 30px 0;
        width: 230px;
        height: 50px;
        @include for-size(phone) {
          margin: 50px auto;
          width: 95%;
        }
      }
    }
  }
