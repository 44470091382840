@import '../../../../assets/styles/variables';

.welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1, p {
    margin: 0;
    color: black;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    text-align: center;
  }

  h1 {
    font-weight: 700;
    margin-top: 100px;
    font-size: 28px;
    line-height: 36px;
  }

  img[alt='levl_method_logo'] {
    height: 16px;
    margin-top: 10px;
  }

  img[alt='hello_image'] {
    margin-top: 50px;
    max-width: 373px;
  }

  p {
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 70px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}