@import '../../../../assets/styles/variables';

.default-modal {
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 85px 54px 45px 54px;
  border-radius: 24px;
  margin: 0 auto;
  width: 440px;
  @include for-size(phone) {
    width: 80%;
    max-width: 320px;
    padding: 70px 20px 30px;
  }

  &-close {
    top: 40px;
    right: 40px;
    width: 20px;
    height: 20px;
    position: absolute;
    @include for-size(phone) {
      top: 30px;
      right: 30px;
    }
  }

  &__container {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    @include for-size(phone) {
      flex-wrap: nowrap;
    }
  }

  &-title {
    font-family: 'SFProDisplay-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #12121d;
    text-align: center;
    @include for-size(phone) {
      text-align: start;
    }
  }

  &-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 320px;
    font-family: 'SF Pro Display', sans-serif;
    color: #8F8F8F;
    text-align: center;
    margin-top: 13px;
    margin-bottom: 45px;
    @include for-size(phone) {
      margin: 35px auto;
      text-align: start;

    }
  }

  &__button {
    width: 45%;
    margin: auto;
    @include for-size(phone) {
      width: 70%;
      margin: 10px auto;
    }
    @include for-size(small-phone) {
      font-size: 12px;
    }

    &-cancel {
      background: #fff;
      color: #12121d;

      &:hover {
        opacity: 0.6;
      }

      @include for-size(small-phone) {
        padding: 10px 7px;
      }
    }

    &-ok {
      font-family: 'SFProDisplay-Regular', sans-serif;
      font-weight: 500;
      color: #fff;
      background: black;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
