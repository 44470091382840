@import '../../../../assets/styles/variables';

.finish-page {
  padding: 60px 70px;
  box-sizing: border-box;

  @include for-specific-size(830px) {
    padding: 0;
  }

  &__content {
    display: flex;
    column-gap: 40px;

    @include for-specific-size(830px) {
      flex-direction: column;
      row-gap: 30px;
    }

    &__carousel {
      flex: 0 0 330px;
      height: 315px;

      @include for-specific-size(830px) {
        width: 80%;
        flex: 0 0 auto;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
      }

      @include for-specific-size(475px) {
        width: 100%;
        flex: 0 0 auto;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }

      .control-dots {
        @include for-specific-size(830px) {
          display: none;
        }
        .dot {
          background: #C4C4C4;
        }

        .selected {
          background: #FE6D02;
        }
      }

      &__banner {
        &__image-wrapper {
          padding-bottom: 40px;
          height: 100%;
          width: 100%;

          @include for-specific-size(830px) {
            padding-bottom: 0;
          }

          img {
            border-radius: 20px;
            height: 100%;
            width: 100%;
            object-position: center center !important;
            object-fit: cover !important;
          }
        }
      }
    }

    &__text {
      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        text-align: left;

        br {
          display: none;
          margin: 0;
          padding: 0;
        }

        @include for-specific-size(830px) {
          text-align: center;
        }

        @include for-specific-size(475px) {
          font-size: 24px;
          line-height: 32px;
          margin-left: auto;
          margin-right: auto;
          br {
            display: block;
          }
        }

      }

      hr {
        margin-top: 55px;
        margin-bottom: 33px;
        background:  rgba(184, 194, 192, 0.36);
        border: none;
        height: 1px;
      }
    }
  }


  button {
    margin: 15% auto 0;
    max-width: 300px;
  }
}

hr.desktop {
  @include for-specific-size(830px) {
    display: none;
  }
}

.finish-page__prediction {
  p {
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
  }

  span {
    font-weight: 600;
    font-size: 28px;
    line-height: 24px;
    text-align: left;
    color: #FE6D02;
    display: block;
  }
}

.finish-page__prediction.desktop {
  @include for-specific-size(830px) {
    display: none;
  }
}

.finish-page__prediction.mobile {
  display: none;

  @include for-specific-size(830px) {
    display: block;

    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 15px;
    }

    span {
      font-weight: 600;
      font-size: 28px;
      line-height: 24px;
      text-align: left;
      color: #FE6D02;
    }
  }

  @include for-specific-size(475px) {
    display: block;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    span {
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #FE6D02;
    }
  }
}