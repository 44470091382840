@import '../../../../assets/styles/variables';

.ideal-weight {
  width: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;

  @include for-size(tablet-phone) {
    margin-top: 105px;
  }

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #B9B9B9;
  }

  &-input-wrapper {
    position: relative;

    span {
      z-index: 2;
      padding-left: 20px;
      padding-right: 20px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      position: absolute;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #AAAAAA;
    }

    input {
      box-sizing: border-box;
      background: #F2F2F2;
      width: 100%;
      height: 74px;
      border-radius: 50px;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      border: none;
      color: black;
      position: relative;

      @include for-size(phone) {
        height: 75px;
        width: 150px;
      }

      &:focus {
        background: #FFFFFF;
        border: 2px solid #FE6D02;
        box-sizing: border-box;
        border-radius: 60px;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent
      }

      &:focus::-moz-placeholder {
        color: transparent
      }

      &:focus:-moz-placeholder {
        color: transparent
      }

      &:focus:-ms-input-placeholder {
        color: transparent
      }

      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        text-align: center;
      }

      ::-moz-placeholder {
        text-align: center;
      }

      :-ms-input-placeholder {
        text-align: center;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}
