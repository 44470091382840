@import '../../assets/styles/variables';

.payment-page {
  position: relative;
  width: 100%;
  margin-top: 180px;
  box-sizing: border-box;
  flex: 1 1 auto;
  column-gap: 40px;
  align-items: stretch;
  padding: 65px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #f2f2f2;

  @include for-size(desktop-laptop) {
    padding: 0;
    flex-direction: column;
    margin-top: 120px;
    background: white;
  }

  @include for-size(phone) {
    padding: 0;
    margin-top: 80px;
    flex-direction: column;
    background: white;
  }

  &__loader {
    position: absolute;
    background: rgba(red, .3);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &-logo {
    cursor: pointer;
    height: 30px;
    margin: 0;
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translate(-50%, -100%);

    @include for-size(desktop-laptop) {
      top: -45px;
    }

    @include for-size(tablet-phone) {
      top: -45px;
    }

    @include for-size(phone) {
      height: 17px;
      top: -30px;
    }
  }
}
