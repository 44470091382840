@import '../../assets/styles/variables';

.layout__contacts-us {
  position: absolute;
  height: 100%;
  width: 100%;
  background: red;
  z-index: 3;
}

.main {
  z-index: 1;
  flex: 1 1 100%;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: none;
}

.auth_main {
  background: #000;
  // background: url("../../assets/images/auth/background.jpg") center center no-repeat;
  //background-size: cover;

  .body-container {
    background-color: transparent;
  }
}
