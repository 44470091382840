@import '../../../../assets/styles/variables';

.info-modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 50px;
  border-radius: 24px;
  @include for-size(phone) {
    width: 80%;
    max-width: 320px;
    padding: 20px;
  }

  &-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #12121d;
    text-align: center;
  }

  &-description {
    font-size: 18px;
    line-height: 24px;
    max-width: 320px;
    text-align: center;
    margin: 30px auto;
    @include for-size(phone) {
      margin: 35px auto;
    }
  }
}
