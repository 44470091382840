@import '../../../../assets/styles/variables';

.time-video {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  padding: 75px 75px 30px;
  max-width: 340px;
  border-radius: 24px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  @include for-size(tablet-phone) {
    width: 65%;
    padding: 75px 40px 30px;

  }

  &-close {
    top: 40px;
    right: 40px;
    width: 20px;
    height: 20px;
    position: absolute;
    @include for-size(phone) {
      top: 30px;
      right: 30px;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #12121D;
    text-align: center;
  }

  &-description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 225px;
    margin: 20px auto 0;

    span {
      color: rgba(18, 18, 29, 0.75s);
    }

  }

  &__time {
    margin: 30px auto;
    display: flex;

    &-item {
      width: 35px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      border-radius: 11px;
      background: #f1f0f0;
      cursor: pointer;
      &-active{
        border: 1px solid #12121d;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;

    &-cancel {
      margin-right: 10px;
      background: transparent;
      color: #12121D;

      &:hover {
        opacity: 0.6;
        background: rgba(18, 18, 29, 0.05s);
      }
    }

    &-ok {
      background: rgba(18, 18, 29, 0.05s);
      color: #12121D;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}