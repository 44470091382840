@import '../../assets/styles/variables';

.question {
  margin-top: 150px;
  background: white;
  flex: 0 0 700px;
  width: 1000px;
  border-radius: 50px;
  position: relative;

  @include for-size(tablet-phone) {
    display: flex;
    flex-direction: column;
    border-radius: 35px 35px 0px 0px;
    margin-top: 120px;
    flex: 1 0 auto;
    width: 100%;
  }

  @include for-size(phone) {
    border-radius: 20px 20px 0px 0px;
    margin-top: 80px;
    width: 100%;
  }

  &-logo {
    filter: invert(1);
    height: 30px;
    margin: 0;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%, -100%);

    @include for-size(tablet-phone) {
      top: -50px;
    }

    @include for-size(phone) {
      height: 17px;
      top: -30px;
    }
  }
}
