@import '../../../../assets/styles/variables';

$paddingInMobile: 20px;

.account {
  padding: 60px 40px;
  width: 68%;
  @include for-size(tablet-phone) {
    width: 100%;
    height: 100vh;
    background: #fff;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    cursor: pointer !important;

    @include for-size(tablet-phone) {
      padding: 30px $paddingInMobile 0;
    }

    &-text {
      font-style: normal;
      font-weight: bold;
      font-family: 'SFProDisplay-Semibold', sans-serif;
      font-size: 28px;
      line-height: 32px;
      color: #12121d;
    }

    &-image {
      margin-right: 10px;
    }
  }

  &__subscription {
    &-title {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      @include for-size(tablet-phone) {
        padding: 0 $paddingInMobile;
      }
    }

    &-active {
      background: #f9f9f9;
      padding: 20px 15px;
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 11px;
        line-height: 24px;
        display: flex;
        align-items: flex-end;
        text-transform: uppercase;

        color: rgba(18, 18, 29, 0.4);
      }
    }

    &__container {
      display: flex;
      flex-direction: column;

      &-top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .custom-button {
          width: 142px;
          height: 42px;
        }
      }

      &_unsubscribe{
        border: 1px solid #E7E7E7;
        background: #F9F9F9;
      }

      &-title {

        font-family: 'SFProDisplay-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: flex-end;

        color: #000000;
      }

      &-description {
        max-width: 280px;
        font-size: 13px;
        line-height: 18px;
        color: rgba(18, 18, 29, 0.6);
        width: 100%;

        &_active {
          color: #12b00b;
        }

        &_trialing {
          color: #8F8F8F
        }

        &_hold {
          color: #8F8F8F
        }
      }
    }
  }

  &__payment-plan {
    @include for-size(tablet-phone) {
      padding: 0 $paddingInMobile;
    }

    &-title {
      font-size: 11px;
      line-height: 24px;
      text-transform: uppercase;
      color: rgba(18, 18, 29, 0.4);
      margin: 10px 0;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 19px;
      padding-bottom: 19px;
      border-bottom: 1px solid #b8c2c0;

      &:last-child {
        padding-bottom: 10%;
      }

      &-subscribe-button {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        background: #000;
        width: 142px;
        height: 42px;
        font-family: 'SFProDisplay-Regular';
        cursor: pointer;
        margin-right: 18px;
        &:hover {
          opacity: 0.7;
        }
      }

      &-title {
        font-size: 16px;
        line-height: 24px;
        color: rgba(18, 18, 29, 0.6);
      }

      &-description {
        font-size: 13px;
        line-height: 18px;
        color: rgba(18, 18, 29, 0.4);
      }

      &-right {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-prise {
        text-align: right;
        color: #FE6D02;
        font-family: 'SFProDisplay-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        margin-right: 55px;
      }
    }
  }

  &__payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 7px 0;

    &:not(:last-child) {
      margin-bottom: 7px;
    }

    &:hover {
      cursor: pointer;
      background: #ececec;
    }

    @include for-size(tablet-phone) {
      padding: 0 $paddingInMobile;
      margin-bottom: 120px;
    }

    &-title {
      font-size: 18px;
      line-height: 24px;
      color: #12121d;
      margin-bottom: 10px;
    }

    &__container {
      display: flex;
      width: 100%;

      &-image {
        width: 22px;
        height: 14px;
      }

      &-number {
        font-size: 13px;
        line-height: 18px;
        color: rgba(18, 18, 29, 0.6);
      }
    }
  }
}
