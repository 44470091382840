@import '../../../../assets/styles/variables';

.header-tertiary {
  flex: 0 0 90px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #D74341 0%, #FE6D02 100%);
  justify-content: space-between;
  padding: 0 60px;
  max-width: 100%;

  @include for-specific-size(1200px) {
    padding: 0 20px;
  }

  @include for-specific-size(1100px) {
    height: auto;
    min-height: auto;
    position: relative;
    flex-direction: row-reverse;
    flex: 0 0 auto;
    padding: 25px 20px;
  }

  &__left {
    display: flex;
    align-items: center;

    @include for-specific-size(1100px) {
      width: 100%;
      flex-wrap: wrap;
    }

    &_container-icon {
      @include for-specific-size(1100px) {
        width: 60%;
      }
    }

    &-logo {
      width: 65px;
      height: 17px;
      cursor: pointer;
      margin-right: 176px;

      @include for-specific-size(1200px) {
        margin-right: 150px;
      }
    }

    &-date {
      display: flex;
      flex-direction: column;
      width: 160px;
      color: white;

      @include for-specific-size(1100px) {
        display: none;
      }

      p {
        font-family: 'SFProDisplay-Semibold', sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        @include for-specific-size(1100px) {
          margin: 0;
          font-style: normal;
          font-weight: 600;
          font-family: 'SFProDisplay-Semibold', sans-serif;

          font-size: 24px;
          line-height: 24px;
        }
      }

      &-today {
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        color: #FED0B9;
      }
    }
  }

  &__right-container {
    @include for-specific-size(1100px) {
      position: fixed;
      overflow: auto;
      top: 0;
      right: 0;
      z-index: 99;
      height: 100vh;
      width: 100vw;
      background: #fff;
      transition: width 300ms, height 300ms, background-color 300ms, transform 300ms;
      transform: translateX(-200%);
    }
  }

  &__right-show-menu {
    @include for-specific-size(1100px) {
      transform: translateX(0);
    }
  }

  &__right {
    display: flex;
    align-items: center;

    @include for-specific-size(1100px) {
      overflow: auto;
      min-width: 100%;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 50px;
      padding-bottom: 100px;

      //padding: 35px 25px;
      box-sizing: border-box;
    }

    &-nav {
      display: flex;
      align-items: center;
      @include for-specific-size(1100px) {
        width: calc(100% - 62px);
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid #b8c2c0;
        margin: 40px auto 0;
        padding-bottom: 35px;
      }

      &-close {
        display: none;
        position: absolute;
        right: 30px;
        top: 15px;
        width: 12px;
        height: 12px;
        opacity: 0.3;
        @include for-specific-size(1100px) {
          display: block;
        }

        &:hover {
          opacity: 1;
        }

        &:before, &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 17px;
          width: 2px;
          background-color: #333;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }

      }

      &-mob {
        display: none;
        @include for-specific-size(1100px) {
          display: flex;
          width: calc(100% - 62px);
          margin: 0 auto;
        }
        flex-direction: column;

        &-item {
          color: $color-text-light;
          text-decoration: none;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: flex-end;
          margin: 15px;
          @include for-specific-size(1100px) {
            margin: 15px 8px;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: flex-end;
            color: #8F8F8F;

            position: relative;
            &:after {
              position: absolute;
              content: '';
              background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 10.3333L5.66667 5.66667L1 1' stroke='%23CACACA' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
              top: 4px;
              background-repeat: no-repeat;
              right: -8px;
              width: 8.67px;
              height: 18.33px;
            }
          }

          &:first-child {
            margin-top: 40px;
            @include for-specific-size(1100px) {
              margin-top: 31px;

            }
          }

          &:last-child {
            margin-top: 70px;
            @include for-specific-size(1100px) {
              color: #000;
              position: relative;
              &:after {
                position: absolute;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 7H18.59L16.29 4.71C16.1968 4.61676 16.1228 4.50607 16.0723 4.38425C16.0219 4.26243 15.9959 4.13186 15.9959 4C15.9959 3.86814 16.0219 3.73757 16.0723 3.61575C16.1228 3.49393 16.1968 3.38324 16.29 3.29C16.3832 3.19676 16.4939 3.1228 16.6158 3.07234C16.7376 3.02188 16.8681 2.99591 17 2.99591C17.1319 2.99591 17.2624 3.02188 17.3843 3.07234C17.5061 3.1228 17.6168 3.19676 17.71 3.29L21.71 7.29C21.801 7.3851 21.8724 7.49725 21.92 7.62C22.02 7.86346 22.02 8.13654 21.92 8.38C21.8724 8.50275 21.801 8.6149 21.71 8.71L17.71 12.71C17.617 12.8037 17.5064 12.8781 17.3846 12.9289C17.2627 12.9797 17.132 13.0058 17 13.0058C16.868 13.0058 16.7373 12.9797 16.6154 12.9289C16.4936 12.8781 16.383 12.8037 16.29 12.71C16.1963 12.617 16.1219 12.5064 16.0711 12.3846C16.0203 12.2627 15.9942 12.132 15.9942 12C15.9942 11.868 16.0203 11.7373 16.0711 11.6154C16.1219 11.4936 16.1963 11.383 16.29 11.29L18.59 9H7C6.73478 9 6.48043 8.89464 6.29289 8.70711C6.10536 8.51957 6 8.26522 6 8C6 7.73478 6.10536 7.48043 6.29289 7.29289C6.48043 7.10536 6.73478 7 7 7ZM1 0C1.26522 0 1.51957 0.105357 1.70711 0.292893C1.89464 0.48043 2 0.734784 2 1V15C2 15.2652 1.89464 15.5196 1.70711 15.7071C1.51957 15.8946 1.26522 16 1 16C0.734784 16 0.480429 15.8946 0.292892 15.7071C0.105356 15.5196 0 15.2652 0 15V1C0 0.734784 0.105356 0.48043 0.292892 0.292893C0.480429 0.105357 0.734784 0 1 0Z' fill='%23FE6D02'/%3E%3C/svg%3E%0A");
                top: 4px;
                left: 71px;
                width: 22px;
                height: 16px;
              }

            }
          }
        }

        .active {

          color: $color-text-dark;
        }
      }

      &-item {
        margin: 0 40px 0 0;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 14px;
        line-height: 24px;
        color: white;
        transition: all 0.3s linear;
        position: relative;
        opacity: 0.7;

        @include for-specific-size(1100px) {
          margin: 0 0 39px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;

          color: #000000;

        }

        &:first-child {
          @include for-specific-size(1100px) {
            margin: 39px 0;
          }
        }

        &:last-child {
          margin: 0 80px 0 0;
          @include for-specific-size(1260px) {
            margin: 0 40px 0 0;
          }

          @include for-specific-size(1200px) {
            margin: 0 40px 0 0;
          }

          @include for-specific-size(1100px) {
            margin: 0 30px 0 0;
          }
        }

        &-img {
          position: absolute;
          left: 6px;
          padding: 0 15px 0 0;
        }

        p {
          margin-left: 40px;
        }

        &.active, &:hover {
          color: white;
          opacity: 1;

          svg, path, circle {
            stroke-opacity: 1;
            fill-opacity: 1;
          }

          @include for-specific-size(1100px) {
            color: #000;
          }
        }
      }
    }

    &-user {
      cursor: pointer;
      display: flex;
      align-items: center;
      @include for-specific-size(1100px) {
        flex-direction: row-reverse;

        box-sizing: border-box;
        background: linear-gradient(90.35deg, #FE6D02 0.09%, #D74341 99.49%);
        width: 100%;
        justify-content: flex-end;
        padding: 50px 30px 50px 30px;
      }

      &-title {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 0;
        color: white;
        font-weight: bold;
        letter-spacing: 1.2px;
        font-size: 14px;
        line-height: 24px;
        margin: 0 10px 0 0;
        cursor: pointer;

        small {
          display: none;
          margin-top: 4px;
          @include for-specific-size(1100px) {
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            font-family: 'SF Pro Display', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #FED0B9;
          }
        }

        @include for-specific-size(1100px) {
          margin: 20px;
          font-style: normal;
          font-weight: bold;
          max-width: unset;
          font-family: 'SFProDisplay-Semibold', sans-serif;
          font-size: 24px;
          line-height: 24px;
        }
      }

      &-arrow {
        filter: invert(1);
        margin: 0 15px 0 0;
        cursor: pointer;
        transition: all 0.3s linear;
        @include for-specific-size(1100px) {
          display: none;
        }
      }

      &-arrow-mobile {
        display: none;
        @include for-size(tablet-phone) {
          display: block;
        }
      }

      &:hover > &-arrow {
        transform: rotate(180deg);
      }
    }
  }
}

img.header-tertiary__right-user-avatar, span.header-tertiary__right-user-avatar {
  flex: 0 0 50px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: block;
  @include for-size(tablet-phone) {
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
  }
}

img.header-tertiary__right-user-avatar {
  object-fit: cover;
  object-position: center center;
}

span.header-tertiary__right-user-avatar {
  border: 5px solid #EB5E28;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: bold;
}
