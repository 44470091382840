@import '../../../../assets/styles/variables';

.my-plan__header-left {
  display: flex;
  align-items: center;
  margin-right: auto;

  @include for-specific-size(1024px) {
    margin-bottom: 20px;
    width: 100%;
    align-items: flex-start;
    padding: 0;
    flex-direction: column;
  }

  &__q-header, &__header {
    min-width: 124px;
    display: flex;
    align-items: center;
  }

  &__q-header {
    padding: 0 46px 0 0;
    @include for-specific-size(600px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__info {
      display: flex;
      flex-direction: column;
      @include for-specific-size(600px) {
        align-items: flex-end;
        flex-direction: row;
        column-gap: 7px;
        margin-bottom: 30px;
      }

      &-title {
        font-family: 'SF Pro Display', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
      }

      &-user {
        font-size: 32px;
        font-family: 'SFProDisplay-Bold', sans-serif;
        font-weight: 700;
      }
    }

    &__item {
      margin-left: 100px;

      @include for-specific-size(780px) {
        margin-left: 50px;
      }

      @include for-specific-size(600px) {
        margin-left: 0;
      }
    }
  }

  &__header {
    flex-wrap: wrap;
    align-items: center;

    @include for-specific-size(1370px) {
      padding: 0 20px 0 0;
    }

    @include for-specific-size(1024px) {
      padding: 0;
    }

    &__icon {
      margin-right: 10px;
      object-fit: scale-down;
      align-self: stretch;
    }

    &__item {
      margin-left: 100px;
    }

    &__container {
      display: flex;
      flex: 0 0 175px;
      width: 175px;
      margin-bottom: auto;

      @include for-specific-size(1024px) {
        display: none;
      }

      img {
        display: block;
      }

      &-none {
        @include for-specific-size(1024px) {
          display: none;
        }
      }
    }

    &__info {
      overflow: hidden;
      text-overflow: ellipsis;
      @include for-specific-size(1024px) {
        margin-bottom: 4px;
        br {
          display: none;
        }

        &-user {
          margin-left: 7px;
        }
      }

      &-title {
        font-family: 'SF Pro Display', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #000000;
        display: flex;
        flex-direction: column;
      }

      @include for-size(small-phone) {
        flex-direction: row;
        column-gap: 10px;
      }


      &-user {
        font-size: 32px;
        font-family: 'SFProDisplay-Bold', sans-serif;
        width: 100%;
        font-weight: 700;
      }
    }

    &__challenge {
      display: none;
      flex: 1 1 100%;
      margin-left: auto;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #B6B6B6;

      @include for-specific-size(1024px) {
        display: block;
      }
    }

    &__description {
      font-size: 13px;
      line-height: 32px;
      text-transform: uppercase;
      color: $color-text-light;
    }
  }
}

.active-day__content-header {
  max-width: 420px;
  text-align-last: left;
  padding: 0 0 0 31px;
  border-left: 1px solid rgba($color-line-primary, .35);
  transform: translate3d(0, 0, 0);

  @include for-specific-size(1270px) {
    padding: 0 0 0 15px;
  }

  @include for-specific-size(1024px) {
    padding: 0;
    border-left: none;
  }

  @include for-size(small-phone) {
    border: none;
    max-width: 315px;
    padding: 0;
    margin: 0;
  }

  &__info {
    font-family: 'SFProDisplay-Semibold', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin: 0 0 10px;
  }

  &__description {
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    color: #8F8F8F;
    margin: 0;
  }
}

.off-day__content-header {
  max-width: 361px;
  text-align-last: left;
  padding: 0 0 0 30px;
  border-left: 1px solid rgba($color-line-primary, .35);

  @include for-size(small-desktop) {
    padding: 0 0 0 20px;
    max-width: 270px;
  }

  @include for-size(tablet-phone) {
    padding: 0 0 0 50px;
    max-width: 350px;
  }

  @include for-specific-size(1024px) {
    border: none;
    max-width: 315px;
    padding: 0;
    margin: 0;
  }

  &__info {
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #000;
    margin: 0 0 10px 0;
    font-weight: bold;
    font-family: 'SFProDisplay-Semibold', sans-serif;
  }

  &__description {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: $color-text-primary;
    max-width: 305px;
    @include for-size(small-phone) {
      max-width: 315px;
    }

    &-workout {
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
      font-family: 'SFProDisplay-Semibold', sans-serif;
      color: $color-text-secondary;
    }
  }
}

.completed-day__content-header {
  max-width: 361px;
  text-align-last: left;
  padding: 0 0 0 30px;
  column-gap: 20px;
  row-gap: 20px;
  border-left: 1px solid rgba($color-line-primary, .35);
  display: flex;
  align-items: center;

  @include for-specific-size(1024px) {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    flex-direction: column;
    border-left: none;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      height: 22px;
      width: 22px;
      padding: 5px;
    }
  }

  &__img {
    border: 3px solid #FE6D02;
    padding: 22px 16px 22px 17px;
    margin: 0;
    border-radius: 50%;

    @include for-specific-size(475px) {

    }
  }

  &__info {
    font-weight: 600;
    font-family: 'SFProDisplay-Semibold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #000000;
    margin: 0 0 7px 0;
  }

  &__description {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: $color-text-light;
    cursor: pointer;
    display: inline-block;
    transition: all 0.3s linear;

    &:hover {
      color: $color-text-dark;
    }
  }
}
