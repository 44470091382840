@import '../../assets/styles/variables';

.saved {
    background: $color-background-white;
    border-radius: 60px;
    padding: 0 0 100px;
    @include for-size(phone) {
    padding: 0 0 50px;
    }

    &__header {
      display: flex;
      padding: 60px 0px 0px 90px;
      @include for-size(tablet-phone) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        width: 100%;
      }

      &-title-container {
        @include for-size(tablet-phone) {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 35px 0 20px 0;
        }

        h2 {
          font-family: "SFProDisplay-Bold", sans-serif;
          font-weight: bold;
          font-style: normal;
          font-size: 28px;
          line-height: 32px;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          color: #000;
          margin: 0;

          @include for-specific-size(1400px) {
            margin-bottom: 20px;
          }

          @include for-specific-size(1024px) {
            margin-bottom: 0;
          }

          @include for-size(tablet-phone) {
           margin-right: 15px;
          }
        }

        img {
          width: 20px;
          height: 23px;
          display: none;
          @include for-size(tablet-phone) {
            display: block;
            margin: 0 15px 0 37px;
          }
        }
      }

      p {
        max-width: 325px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin: auto 30px;
        color: $color-text-primary;
        @include for-size(tablet) {
          width: 50%;
          max-width: 50%;
        }
        @include for-size(phone) {
          width: 80%;
          max-width: 80%;
        }
      }
    }

    &__container {
      width: 100%;
      height: 100%;

    }
  }
