@import '../../../../assets/styles/variables';

.landing-header {
  position: sticky;
  top: 0;
  height: 90px;
  box-sizing: border-box;
  z-index: 2;
  width: 100%;
  padding: 20px 80px;
  display: flex;
  transition: .3s all;

  &[data-hide = true] {
    transform: translate(0, -110%);
  }

  @include for-specific-size(1280px) {
    padding: 20px 40px;
  }

  @include for-specific-size(820px) {
    padding: 20px 20px;
  }

  &__logo {
    margin-top: auto;
    margin-bottom: auto;
    display: block;
  }

  &__links {

    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: center;
    height: 100%;

    @include for-specific-size(820px) {
      display: none;
    }

    &__active {
      opacity: 1 !important;
    }

    a {
      text-decoration: none;
      color: black;
      opacity: .5;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      font-family: 'HelveticaNeueCyr-Medium', sans-serif;

      &:not(:last-child) {
        margin-left: 60px;
      }

      &:last-child {
        margin-left: 25px;
        opacity: 1;
      }

      @include for-specific-size(930px) {
        &:not(:last-child) {
          margin-left: 30px;
        }

        &:last-child {
          margin-left: 25px;
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

    }

    &__login-button {
      border: 3px solid #FFFFFF;
      border-radius: 10px;
      font-size: 16px !important;
      padding: 0 20px;
      height: 100%;
      display: flex;
      opacity: 1 !important;
      font-family: 'HelveticaNeueCyr-Bold', sans-serif !important;
      align-items: center;
      color: black !important;
    }

    &__start-button {
      font-family: 'HelveticaNeueCyr-Bold', sans-serif !important;
      border-radius: 10px !important;
      padding: 0 20px !important;
      font-weight: 700 !important;
      font-size: 16px !important;
      height: 52px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      color: #19171B !important;
      background: white;
    }
  }

  &__mobile-menu {
    display: none;
    align-items: center;
    margin-left: auto;

    @include for-specific-size(820px) {
      display: flex;
      position: relative;
    }

    &__burger {
      background: none;
      outline: none;
      border: none;
      z-index: 1;

      &[data-is-open = true] {
        svg {
          path {
            fill: white;
          }
        }
      }
    }

    &__links {
      background: rgba(0, 0, 0, .6);
      height: 100vh;
      position: fixed;
      top: 0;
      right: 0;
      transform: translate(100%, 0);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 80px 30px 30px 30px;

      a {
        color: white;
        text-decoration: none;
        font-family: HelveticaNeueCyr-Medium, sans-serif;
        margin-bottom: 20px;
        font-size: 18px;
      }

      &__active {
        color: #c3c3c3 !important;
      }


      &[data-is-open = true] {
        transform: translate(0, 0);
      }
    }
  }
}
