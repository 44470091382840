.loader-wrapper {
  position: absolute;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 48px;
    animation: logoLoaderAnim 1s infinite ease-in-out;
    color: #EB5E28;
    font-family: "SFProDisplay-Bold", sans-serif;
    background-color: red;
    background-image: linear-gradient(113deg, rgba(235,94,40,1) 0%, rgba(235,94,40,1) 50%, rgba(225,225,225,1) 50%, rgba(225,225,225,1) 100%);
    background-size: 200% 200%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

@keyframes logoLoaderAnim {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}
