@import "../../assets/styles/variables";

.no-subscription {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1 1 100%;

  span {
    height: 140px;
    width: 100%;
    background: #FE6D02;
  }

  &__content {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    justify-content: center;
    @include for-specific-size(600px) {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
      color: black;
    }

    img {
      width: 140px;
    }
  }
}
