@import '../../../../assets/styles/variables';

.info-question {
  &__item {
    width: 90%;
    max-width: 590px;
    margin: 0 auto;

    @include for-size(small-phone) {
      width: 100%;
      max-width: 100%;
    }
  }

  &-image {
    margin: auto;
    width: 373px;
    object-fit: cover;
    @include for-size(tablet-phone) {
      width: 100%;
    }
  }

  &-text {
    white-space: pre-wrap;
    max-width: 540px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: #8F8F8F;
    margin: 20px auto;
    @include for-size(tablet-phone) {
      width: 100%;
    }
  }
}
