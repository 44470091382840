@import '../../../../../assets/styles/variables';

.active-day__content {
  display: flex;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    opacity: 0;
    content: '';
    z-index: 9999;
    pointer-events: none;
  }

  @include for-size(tablet-phone) {
    flex-direction: column;
  }

  &-left {
    width: 68.7%;
    margin: 0 40px 0 0;

    @include for-size(tablet-phone) {
      width: 100%;
    }

    &__video {
      position: relative;
      height: 425px;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all 0.3s linear;

      &__hint {
        position: absolute;
        pointer-events: all;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      img {
        border-radius: inherit;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      @include for-size(phone) {
        border-radius: 20px;

        height: 190px;
      }

      &::before {
        content: url('../../../../../assets/images/vectors/arrow-down1.svg');
        display: none;
        position: absolute;
        top: -77px;
        left: 150px;
        @include for-size(phone) {
          top: -77px;
          left: 50px;
        }
        @include for-size(laptop) {
          top: -77px;
          left: 220px;
        }
      }

      &::after {
        content: attr(data-after);
        display: none;
        color: #fff;
        position: absolute;
        font-size: 20px;
        top: -90px;
        left: 200px;

        @include for-size(phone) {
          top: -90px;
          left: 100px;
        }
        @include for-size(laptop) {
          top: -90px;
          left: 270px;
        }
      }

      &:hover {
        box-shadow: 0px 0px 10px 0px $color-text-tertiary;
      }

      &-btn {
        margin-top: auto;
        margin-bottom: 70px;
        font-family: 'SF Pro Display', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        background: #FE6D02;
        border-radius: 60px;
        z-index: 1;
        padding: 17px 74px;
        cursor: pointer;
        outline: none;
        border: none;

        @include for-specific-size(768px) {
          margin-top: auto;
          margin-bottom: 20px;
        }

        &:hover,
        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      padding: 0 32px;
      margin: 30px 0 44px 0;
      @include for-size(tablet-phone) {
        margin: 16px 0 37px;
        padding: 0;
        flex-wrap: wrap;
      }

      &-left {
        display: flex;
        align-items: center;
        @include for-size(tablet-phone) {
          width: 100%;
          justify-content: space-between;
        }

        &-title {
          font-family: 'SF Pro Display', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
        }

        &-info {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $color-text-primary;

          &-cal {
            @include for-size(phone) {
              display: none;
            }
          }

          &__img {
            margin: 0 7px 0 16px;
          }
        }
      }

      &-right {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: #000;
        @include for-size(tablet-phone) {
          width: 100%;
          justify-content: unset
        }

        &-title {
          font-weight: 600;
          font-family: 'SFProDisplay-Semibold', sans-serif;
          margin: 0 9px 0 0;
        }

        &-subtitle {
          color: #8F8F8F;
        }
      }
    }
  }

  &-right {
    width: calc(100% - 40px - 68.7%);
    @include for-size(tablet-phone) {
      width: 100%;
    }

    &__workout {
      margin: 0 0 75px 0;
      position: relative;

      &__hint {
        position: absolute;
        pointer-events: all;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      @include for-size(phone) {
        margin: 0 0 37px 0;

      }

      &::before {
        content: url('../../../../../assets/images/vectors/arrow-up2.svg');
        display: none;
        position: absolute;
        top: 390px;
        left: 280px;
        @include for-size(phone) {
          left: 170px;
        }
        @include for-size(laptop) {
          left: 0;
        }
      }

      &::after {
        content: attr(data-after);
        display: none;
        color: #fff;
        position: absolute;
        font-size: 20px;
        top: 380px;
        left: 45px;
        width: 290px;
        @include for-size(phone) {
          left: 0;
          width: 160px;
        }
        @include for-size(laptop) {
          width: 220px;
          left: -245px;
        }
      }

      &__title {
        font-size: 16px;
        line-height: 26px;
        text-transform: uppercase;
        color: #000;
        margin: 0 0 16px 0;
        font-weight: bold;
        font-family: 'SFProDisplay-Semibold', sans-serif;
        @include for-size(phone) {
          margin: 0 0 15px 0;
        }
      }

      &__video {
        position: relative;
        width: 100%;
        height: 214px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s linear;
        cursor: pointer;
        @include for-size(phone) {
          height: 194px;
        }

        &:hover {
          box-shadow: 0px 0px 10px 0px $color-text-tertiary;
        }

        &-img {
          width: 100%;
          height: 100%;
          border-radius: inherit;
          position: absolute;
          object-fit: cover;
        }
      }

      &__description {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px 0 0 0;
        font-size: 14px;
        line-height: 24px;
        color: $color-text-primary;
        @include for-size(phone) {
          margin: 13px 0 0 0;

        }

        &-title {
          font-family: 'SF Pro Display', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }

        &-duration {
          display: flex;
          align-items: center;

          &-clock {
            margin: 0 9px 0 0;
          }
        }
      }

      &__equipment {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: $color-text-primary;
        @include for-size(tablet-phone) {
          width: 100%;
          justify-content: unset;
        }

        &-title {
          margin: 0 9px 0 0;
          font-weight: 600;
          font-family: 'SFProDisplay-Semibold', sans-serif;
          font-size: 14px;
          line-height: 24px;
          color: #000;
        }
      }
    }

    &__view-plan {
      &::before {
        content: url('../../../../../assets/images/vectors/arrow-down2.svg');
        display: none;
        position: absolute;
        top: -65px;
        left: 260px;
        @include for-size(phone) {
          left: 200px;
        }
        @include for-size(laptop) {
          left: 90px;
        }
      }

      &::after {
        content: attr(data-after);
        display: none;
        color: #fff;
        position: absolute;
        top: -110px;
        left: 0;
        width: 280px;
        font-size: 20px;
        text-align: start;
        @include for-size(phone) {
          left: 0;
          top: -120px;
        }
        @include for-size(laptop) {
          left: -180px;
        }
      }

      &__btn {
        border: 1px solid $color-border-tertiary;
        box-sizing: border-box;
        border-radius: 21px;
        width: 100%;
        height: 68px;
        background-color: $color-background-white;
        cursor: pointer;
        font-family: SFProDisplay-Bold, sans-serif;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #000;
        transition: all 0.4s linear;
        position: relative;

        &__hint {
          position: absolute;
          pointer-events: all;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        @include for-size(tablet-phone) {
          max-width: 100%;
        }

        &:before {
          position: absolute;
          content: url('../../../../../assets/images/myPlan/arrow.svg');
          right: 30%;
          top: 35%;
          transition: all 0.3s linear;
          @include for-size(phone) {
            right: 10%;
          }
        }

        &:hover {
          color: $color-text-white;
          background-color: $color-border-tertiary;

          &:before {
            right: 10%;
          }
        }
      }
    }
  }
}
