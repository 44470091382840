@import '../../../../assets/styles/variables';

.chekBox_question {
  width: auto;
  margin: auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: 100px;

  @include for-size(tablet-phone) {
    column-gap: 60px;
  }

  @include for-size(phone) {
    margin-top: 45px;
    column-gap: 50px;
  }

  @include for-size(small-phone) {
    margin-top: 15px;
    width: 100%;
  }

  &__label {
    flex: 0 1 25%;
    break-inside: avoid;
    margin-bottom: 15px;
    display: flex;
    column-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @include for-size(tablet-phone) {
      text-align: start;
    }

    @include for-size(phone) {
      border-bottom: 1px solid rgba(#B8C2C0, .35);
      flex: 1 1 100%;
      text-align: start;
    }

   p {
     font-size: 16px;
     line-height: 24px;
     color: #000000;

     @include for-size(phone) {
       padding-bottom: 10px;
       font-size: 14px;
       line-height: 24px;
     }

     &:before, &:after {
       width: 16px;
       height: 16px;
       box-sizing: border-box;
       position: absolute;
       content: '';
       left: 0;
       top: 50%;
       transform: translate(0, -50%);
       pointer-events: none;

       @include for-size(phone) {
         top: calc(50% - 5px)
       }
     }

     &:before {
       width: 16px;
       height: 16px;
       background: #E5E5E5;
       border-radius: 4px;
     }

     &:after {
       width: 6px;
       height: 10px;
       border-bottom: 2px solid white;
       border-right: 2px solid white;
       transform: rotate(45deg) translate(-15%, -75%);
       opacity: 0;
     }
   }

    &__input {
      padding: 0;
      margin: 0;
      width: 16px;
      height: 16px;
      opacity: 0;
    }

    &__input:checked + p {
      &:after {
        opacity: 1;
      }

      &:before {
        background: #FE6D02;
      }
    }
  }
}

.chekBox_question_small {
  columns: 2;
}
