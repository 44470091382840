@import '../../assets/styles/variables';

.week-navigation {
  margin-left: auto;
  display: flex;
  height: 85px;
  column-gap: 16px;
  position: relative;

  @include for-specific-size(1275px) {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  @include for-specific-size(1024px) {
    margin-top: 0;
  }

  @include for-size(small-phone) {
    width: 100%;
    justify-content: center;
    column-gap: 6px;
  }


  &__item {
    height: 100%;
    width: 52px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    background: #000000;
    border-radius: 12px;
    cursor: pointer;

    @include for-size(phone) {

      height: 58px;
      padding: 4px 0;
    }

    p {
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
    }

    span, img {
      margin-top: auto;
      margin-bottom: auto;
    }

    span {
      height: 5px;
      width: 5px;
      background: #FE6D02;
      border-radius: 100%;
    }

    img {
      display: block;
      width: 17px;
      filter: invert(1);
    }
  }
}

.selected {
  box-shadow: 0 0 2px 2px #FE6D02;
}
