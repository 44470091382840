@import '../../assets/styles/variables';

.challenge {
  border-radius: 60px;
  background: white;
  padding: 50px 90px 30px;
  flex: 1 1 100%;

  @include for-size(tablet-phone) {
    padding: 30px 30px;
    border-radius: 0;
  }

  &__item {
    border-radius: 20px;
    display: flex;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    flex: 0 0 32%;

    @include for-specific-size(1340px) {
      flex: 0 0 48%;
    }

    @include for-specific-size(1024px) {
      flex: 0 0 100%;
    }

    &_img {
      width: 103px;
      height: 87px;
      border-radius: 20px;
      object-fit: cover;
    }

    &_body {
      width: calc(100% - 103px);
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 10px 0;

    }

    &_top {
      p {
        font-family: 'SFProDisplay-Semibold';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        @include for-size(tablet-phone) {
          font-size: 16px;

        }
      }
    }

    &_bottom {
      display: flex;
      justify-content: space-between;

      &_time {
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #8F8F8F;
        font-family: 'SFProDisplay-Regular';
        position: relative;
        margin-left: 19px;

        &:after {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 3.75V7.5L10 8.75M13.75 7.5C13.75 10.9518 10.9518 13.75 7.5 13.75C4.04822 13.75 1.25 10.9518 1.25 7.5C1.25 4.04822 4.04822 1.25 7.5 1.25C10.9518 1.25 13.75 4.04822 13.75 7.5Z' stroke='%23FE6D02' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          width: 15px;
          height: 15px;
          position: absolute;
          left: -17px;
          top: 3px;
        }
      }

      &_day {
        font-family: 'SFProDisplay-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: #8F8F8F;
        @include for-size(tablet-phone) {
          display: none;
        }
      }
    }
  }

  &__weak {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(185, 185, 185, 0.4);
    //display: flex;
    &_title {
      position: relative;
      display: flex;
      padding-top: 20px;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;

      h2 {
        margin: 0;
        font-family: 'SFProDisplay-Semibold';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
      }


      @include for-size(tablet-phone) {
        h1 {
          font-size: 18px;
        }
      }

      &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 10.3333L5.66667 5.66667L1 1' stroke='%23FE6D02' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        position: absolute;
        right: 1px;
        top: 50%;
        transform: translateY(-50%);
        width: 7px;
        height: 12px;
      }

      &_active {
        &:after {
          transform: rotate(90deg);
        }
      }

      &_disabled {
        &:hover {
          opacity: 0.5;
        }
      }

    }

    &_body {
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap;
      transition: .55s opacity, .55s visibility;
      row-gap: 20px;
      justify-content: space-between;

      &_disabled {
        height: 0;
        overflow: hidden;
      }

      &_active {
        height: 100%;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: left;

    @include for-size(tablet-phone) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 20px;
    }

    p {
      max-width: 430px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #8F8F8F;
      font-family: 'SFProDisplay-Regular';
    }

    h1 {
      margin: 0;
      font-family: "SFProDisplay-Bold", sans-serif;
      font-style: normal;
      font-size: 28px;
      line-height: 32px;
      text-transform: capitalize;
      color: #000;
      padding-right: 55px;
      max-width: 130px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
}
