@import '../../assets/styles/variables';

.challenges {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding: 40px 40px 20px 40px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 60px;
  flex: 1 1 auto;
  max-width: 1280px;
  margin: auto;

  @include for-size(tablet-phone) {
    border-radius: 30px 30px 0px 0px;
  }

  @include for-specific-size(480px) {
    padding: 30px 0 30px 0;
  }

  &__head {
    display: flex;
    flex-direction: row;
    padding-left: 67px;
    margin-bottom: 33px;

    @include for-size(tablet-phone) {
      padding: 0;
      margin: 0 0 40px 0;
      flex-direction: column;
    }

    @include for-specific-size(480px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    &-title {
      max-width: 150px;
      font-size: 28px;
      font-family: 'SFProDisplay-Bold', sans-serif;
      @include for-size(tablet-phone) {
        max-width: 100%;
        font-size: 20px;
        line-height: 32px;
        position: relative;
        margin-left: 35px;
        &:before {
          position: absolute;
          top: 1px;
          left: -35px;
          content: '';
          width: 27px;
          height: 27px;
          background-image: url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.26239 15.5641L15.564 8.26245M19.0227 11.7211L11.7211 19.0228M8.26239 22.4814L6.80206 23.9418C6.64834 24.0955 6.41776 24.0955 6.26404 23.9418L3.34339 21.0211C3.18967 20.8674 3.18967 20.6368 3.34339 20.4831L4.80371 19.0228M19.0227 4.80378L20.483 3.34345C20.6368 3.18973 20.8673 3.18973 21.0211 3.34345L23.9417 6.26411C24.0954 6.41783 24.0954 6.64841 23.9417 6.80213L22.4814 8.26245M14.4496 22.0587L11.2983 25.21C11.1062 25.4021 10.7988 25.4021 10.6066 25.21L1.88306 16.4864C1.69091 16.2943 1.69091 15.9868 1.88306 15.7947L5.03429 12.6434C5.22644 12.4513 5.53388 12.4513 5.72603 12.6434L14.4496 21.367C14.6417 21.5591 14.6417 21.8666 14.4496 22.0587ZM25.2099 11.2984L22.0587 14.4496C21.8665 14.6418 21.5591 14.6418 21.3669 14.4496L12.6434 5.72609C12.4512 5.53394 12.4512 5.22651 12.6434 5.03436L15.7946 1.88312C15.9868 1.69097 16.2942 1.69097 16.4863 1.88312L25.2099 10.6067C25.402 10.7988 25.402 11.1063 25.2099 11.2984Z' stroke='%23FE6D02' stroke-width='1.7' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");

        }
      }
    }

    &-description {
      max-width: 430px;
      font-family: 'SFProDisplay-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin: auto 0 auto 50px;
      /* or 150% */
      @include for-size(tablet-phone) {
        margin: 0;
      }

      color: #8F8F8F;
    }
  }

  &__content {
    column-gap: 20px;
    overflow: hidden;
    position: relative;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 535px !important;

    @include for-specific-size(740px) {
      height: 470px !important;
    }

    &_arrow {
      cursor: pointer;
      height: 30px;

      @include for-specific-size(480px) {
        display: none;
      }
    }

    &__slider {
      scroll-behavior: smooth;
      height: 100%;
      overflow: hidden;
      overflow-x: scroll;
      overflow: -moz-scrollbars-none;
      scroll-behavior: auto;
      white-space: nowrap;
      display: block;
      z-index: 1;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;
      align-items: center;

      &::-webkit-scrollbar {
        display: none;
      }

      &__slide + &__slide {
        margin-left: 15px;
      }

      &__active-slide {
        height: 533px !important;
        width: 370px !important;

        @include for-specific-size(740px) {
          width: 315px !important;
          height: 468px !important;
        }
      }

      &__slide {
        margin-top: auto;
        margin-bottom: auto;
        transition: .3s all;
        user-select: none;
        display: inline-block;
        height: 430px;
        flex: 0 0 350px;
        width: 350px;
        overflow: hidden;
        position: relative;

        @include for-specific-size(740px) {
          flex: 0 0 315px;
          width: 315px;
          height: 400px;
        }

        @include for-specific-size(480px) {
          flex: 0 0 315px;
          width: 315px;
          height: 400px;

          &:first-child {
            margin-left: 15px;
          }

          &:last-child {
            margin-right: 15px;
          }
        }

        &_image {
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center center;
          border-radius: 20px;
        }

        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 2;
          height: 100%;
          width: 100%;
          position: relative;
          justify-content: center;

          &_title {
            font-family: "SFProDisplay-Semibold", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-transform: capitalize;
            color: #FFFFFF;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
            margin: 0 0 27px 0;
          }

          &_join-button {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            margin: 0 0 12px 0;
          }

          &_more {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}
