@import '../../assets/styles/variables';

.my-plan {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding: 40px 40px 20px 40px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 60px;

  @include for-size(tablet-phone) {
    border-radius: 30px 30px 0px 0px;
  }

  @include for-size(phone) {
    padding: 30px;
  }

  &__date {
    display: none;

    @include for-size(tablet-phone) {
      padding: 20px;
      display: flex;
      flex-direction: column;
      width: 280px;
    }

    p {
      font-family: 'SFProDisplay-Semibold', sans-serif;
      line-height: 24px;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
    }

    &_today {
      color: #8F8F8F;
      margin: 0;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__header {
    display: flex;
    column-gap: 20px;
    margin-bottom: 50px;

    @include for-specific-size(1275px) {
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;
    }

    @include for-size(phone) {
      margin-bottom: 20px;
    }
  }

  &__day_content {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
  }
}
