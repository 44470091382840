@import '../../../../assets/styles/variables';

.select-question {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;

  @include for-size(phone) {
    width: 100%;
  }

  &__label {
    margin-bottom: 23px;
    font-size: 16px;
    line-height: 24px;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include for-size(phone) {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(#B8C2C0, .35);
    }

    p {
      padding-left: 15px;

      @include for-size(phone) {
        width: 90%;
        text-align: start;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }

      position: relative;

      &:before, &:after {
        box-sizing: border-box;
        border-radius: 50%;
        opacity: 0;
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translate(-100%, -50%);
        pointer-events: none;
      }

      &:before {
        background: white;
        border: 2px solid #FE6D02;
        width: 20px;
        height: 20px;
      }

      &:after {
        background: #FE6D02;
        height: 10px;
        width: 10px;
        left: -5px;
      }
    }

    &__item:checked + p {
      &:after, &:before {
        opacity: 1;
      }
    }


    &__item {
      width: 20px;
      height: 20px;

      &:checked {
        opacity: 0;
      }
    }
  }
}
