@import "../../../assets/styles/variables.scss";

.player_modal {
  opacity: 1;
  left: 0;
  top: 0;
  position: fixed;
  margin: 0;
  border: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 99999;
  background: black;

  &__config {
    z-index: 5;
    position: absolute;
    background: rgba(0, 0, 0, .8);
    left: 20px;
    top: 20px;
    padding: 15px;
    color: white;
    font-weight: bold;
    font-size: 18px;

    span {
      margin-left: 15px;
      color: #EB5E28;
    }

  }

  &__content {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    column-gap: 20px;
    display: flex;
    flex-direction: column;
  }

  &__close_modal {
    pointer-events: all;
    user-select: none;
    padding: 11px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 27px;
    transform: scaleY(.7);
    color: white;
    background: rgba(black, .6);

    &:hover {
      font-weight: bold;
    }
  }

  &__watch-data {
    border-radius: 50%;
    position: absolute;
    left: 20px;
    bottom: 20px;

    &_marker {
      &_low, &_medium, &_high, &_none {
        box-shadow: 2px 2px 3px rgba(0, 0, 0, .3);
        height: 16px;
        width: 16px;
        border-radius: 100%;
        display: block;
      }

      &_low {
        background: #12b00b;
      }

      &_medium {
        background: #f86d3c;
      }

      &_high {
        background: red;
      }

      &_none {
        display: none;
      }
    }

    &_hr {
      &_easy, &_medium, &_hard {
        font-family: inherit;
        display: block;
      }

      &_easy {
        color: #12b00b;
      }

      &_medium {
        color: #EB5E28;
      }

      &_hard {
        color: red;
      }
    }
  }

  &__watch-modal {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;

    &__wrapper {
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
      background: white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
      font-size: 18px;

      b {
        font-size: 1.2em;
      }

      p {
        font-size: 1em;
        text-align: center;
        max-width: 240px;
      }

      button {
        cursor: pointer;
        background: none;
        border: none;
        text-decoration: underline;
        color: #6c6c72;
      }
    }
  }

  &__video {
    box-sizing: border-box;
    background: black;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    iframe {
      background: black;
      width: 100% !important;
      height: 100% !important;
    }

    &__controls {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      @media screen and (max-width: 1200px) and (orientation: landscape) {
        width: 90%;
        left: 5%;
        right: 5%;
      }

      &__play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: .3s all;
        z-index: 999;

        img {
          height: 48px;
          width: 48px;
          cursor: pointer;
          transition: .3s all;

          @include for-specific-size(768px) {
            height: 36px;
            width: 36px;
          }

          &:hover {
            height: 64px;
            width: 64px;
          }

          &[alt=pauseButtonIcon] {
            filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));

            &:hover {
              filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));
            }
          }

          &[alt=playButtonIcon] {
            filter: invert(75%) sepia(56%) saturate(7500%) hue-rotate(356deg) brightness(99%) contrast(105%) drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));

            &:hover {
              filter: invert(75%) sepia(56%) saturate(7500%) hue-rotate(356deg) brightness(99%) contrast(105%) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));
            }
          }
        }
      }

      &__volume {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0, -50%);
        height: 230px;
        background: rgba(0, 0, 0, 0.3);
        width: 50px;
        border-radius: 30px;
        transition: .3s all;
        overflow: hidden;

        @include for-specific-size(768px) {
          height: 150px;
          right: 15px;
          width: 40px;
        }

        &_counter {
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          color: white;
          z-index: 3;
        }

        &_bar {
          pointer-events: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: #FE6D02;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input[type=range][orient=vertical] {
          cursor: pointer;
          opacity: 0;
          writing-mode: bt-lr;
          -webkit-appearance: slider-vertical;
          width: 100%;
          height: 100%;
        }
      }

      &__parts {
        position: absolute;
        left: 50%;
        display: flex;
        transition: .3s all;
        align-items: center;
        justify-content: space-between;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60vw;

        img {
          pointer-events: all;
          height: 24px;
          width: 24px;
          cursor: pointer;
          transition: .3s all;
          filter: invert(75%) sepia(56%) saturate(7500%) hue-rotate(356deg) brightness(99%) contrast(105%) drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));

          &:hover {
            filter: invert(75%) sepia(56%) saturate(7500%) hue-rotate(356deg) brightness(99%) contrast(105%) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));
          }

          &[alt='nextIcon'] {
            margin-left: auto;
          }

          &[alt='prevIcon'] {
            transform: rotate(-180deg);
            margin-right: auto;
          }
        }
      }

      &__fullscreen {
        position: absolute;
        height: 25px;
        width: 25px;
        bottom: 40px;
        right: 40px;
        transition: .3s all;
        pointer-events: all;

        button {
          cursor: pointer;
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          outline: none;
          padding: 0;
          margin: 0;
        }

        &:hover {
          height: 27px;
          width: 27px;
        }

        @include for-specific-size(768px) {
          height: 20px;
          width: 20px;
          bottom: 40px;

          &:hover {
            height: 22px;
            width: 22px;
          }
        }
      }
    }
  }
}
