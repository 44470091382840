@import '../../../../assets/styles/variables';

.contact-us {
  width: 60%;
  margin: 60px 40px;
  max-width: 442px;
  @include for-size(tablet-phone) {
    max-width: 100%;
    width: 100vw;
    margin: 0;
    padding: 30px;
    height: 100vh;
    background: #fff;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 100;
    box-sizing: border-box;
  }

  &__content {
    margin: 0 0 60px 0;

    &-back {
      cursor: pointer !important;
      display: flex;
      margin-bottom: 45px;
    }

    &-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      color: #000000;
      font-family: 'SFProDisplay-Semibold';
    }

    &-description {
      font-size: 14px;
      line-height: 21px;
      color: rgba(18, 18, 29, 0.6);
    }
  }

  &__form {
    .custom-input {
      padding: 0 0 15px;
      margin-top: 10px;
      &::placeholder {
        font-size: 14px;
        line-height: 21px;
        color: $color-text-light;
      }

      &:focus {
        border: none;
      }
    }

    .custom-input__label,
    .textarea-label {
      color: rgba(18, 18, 29, 0.3);
    }

    .custom-button {
      margin: 60px 0;
      width: 230px;
      height: 50px;
      @include for-size(phone) {
        margin: 50px auto;
        width: 95%;
      }
    }
  }
}
