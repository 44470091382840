.player-loader {
    &__actions-blocking-container {
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        position: absolute;
    }

    &__backdrop {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1001;
    }
    &__container {
        width: 58px;
        height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: rgba(0, 0, 0, 0.7);

        border: 1px solid #fe6d02;
        border-radius: 6px;

        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        z-index: 30;
    }

    &__dot-flashing {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #fe6d02;
        color: #fe6d02;
        animation: dotFlashing 1s infinite linear alternate;
        animation-delay: 0.5s;
    }

    &__dot-flashing::before,
    &__dot-flashing::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    &__dot-flashing::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #fe6d02;
        color: #fe6d02;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
    }

    &__dot-flashing::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #fe6d02;
        color: #fe6d02;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
    }
}

@keyframes dotFlashing {
    0% {
        background-color: #fe6d02;
    }
    50%,
    100% {
        background-color: rgba(255, 255, 255, 0.9);
    }
}
