@import '../../../../assets/styles/variables';

.payment-slider {
  box-sizing: border-box;
  background: url("../../../../assets/images/paymentPage/img.png") center center no-repeat;
  background-size: cover;
  border-radius: 50px;
  max-width: 784px;
  padding-bottom: 35px;

  @include for-size(small-desktop) {
    max-width: 620px;
  }


  @include for-size(desktop-laptop) {
    max-width: 100%;
    width: 100%;
  }

  @include for-size(phone) {
    h3 {
      font-size: 24px !important;
      line-height: 32px !important;
      margin-bottom: 20px !important;
    }

    h4 {
      font-size: 16px !important;
      line-height: 24px !important;
      margin-bottom: 30px !important;
    }
  }

  h3, h4 {
    font-family: 'SF Pro Display', sans-serif;
    color: #FFFFFF;
    text-align: center;
    font-style: normal;
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    margin-top: 100px;
    font-weight: 700;
    font-size: 52px;
    line-height: 55px;
    margin-bottom: 0;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 1);
  }

  h4 {
    max-width: 80%;
    margin-top: 30px;
    margin-bottom: 60px;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    text-shadow: 1px 1px 12px rgba(0, 0, 0, 1);
  }


  &__item {
    position: relative;

    &__key {
      position: absolute;
      width: 12px;
      height: 12px;
      top: 50%;
      transform: translateY(-50%) rotate(10deg);
      cursor: pointer;

      &-left {
        left: -15px;
        border-top: 2px solid #c4c4c4;
        border-left: 2px solid #c4c4c4;
        transform: rotate(-45deg);
      }

      &-right {
        right: -15px;
        border-top: 2px solid #c4c4c4;
        border-right: 2px solid #c4c4c4;
        transform: rotate(45deg);
      }
    }

    img {
      margin: 0 auto 15%;
    }

    &-comment-item {
      position: relative;
      border-radius: 40px;
      width: 70%;
      background: #fff;
      padding: 30px 35px;
      margin: auto auto 5px;
      box-sizing: border-box;
      @include for-size(tablet-phone) {
        width: 65%;
      }

      @include for-size(small-phone) {
        width: 315px;
        padding: 20px;
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
      color: rgba(18, 18, 29, 0.81);
    }

    &__container {
      display: flex;
      margin: 5px auto;

      p {
        font-size: 14px;
        line-height: 24px;
        color: rgba(18, 18, 29, 0.81);
      }
    }

    &-name {
      margin-right: 6px;
      font-weight: bold;
    }
  }
}
