@import '../../../../assets/styles/variables';

.age-select-question {
  margin-top: 90px;
  margin-left: 0;
  margin-right: 0;
  border-radius: 90px;
  border: none;
  padding: 25px 75px;
  background: url('http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png') no-repeat right #F2F2F2;
  -webkit-appearance: none;
  background-position-x: 85%;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: black;
  text-align: center;

  @include for-size(tablet-phone) {
    margin-top: 105px;
    width: 200px;
    height: 75px;
  }

  &:focus {
    border: 2px solid #FE6D02;
    box-sizing: border-box;
    border-radius: 60px;
  }
}
