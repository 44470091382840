.popup-select-rate {
  flex: 0 0 40px !important;
  min-width: 0 !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  background: black;
  border-radius: 11px;
}

.popup-selected-rate {
  border: 1px solid #FE6D02;
  box-shadow: 0px 0px 4px #FE6D02
}

.popup-select-container {
  position: relative;
  display: flex;
  column-gap: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.popup-select-description {
  font-family: 'SFProDisplay-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
  margin-top: 10px;
  margin-bottom: 25px;

  span {
    color: #8F8F8F;
  }
}
.popup-select-submit-hint {
  position: absolute;
}

.popup-select-container-hint {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.popup-select-submit {
  padding: 9px 25px !important;
  width: auto;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  background: black;
  margin-left: auto;
  margin-right: auto;
  font-family: 'SFProDisplay-Regular', sans-serif;
}

.popup-select-submit-not-now {
  background: black;
  font-family: 'SFProDisplay-Regular', sans-serif;
  font-weight: 400;
  padding: 13px 0 !important;
  width: 50px;
  text-align: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  border: none;
}
