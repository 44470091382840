@import '../../../../assets/styles/variables';

.answer-to-questions {
  display: flex;
  justify-content: center;
  flex: 1 1 100%;
  align-items: stretch;

  hr {
    box-sizing: border-box;
    margin: 0 70px;
    width: 1px;
    height: auto;
    border: none;
    background: #B8C2C0;
    opacity: .35;

    @include for-size(tablet-phone) {
      margin: 0 30px;
    }

    @include for-size(phone) {
      display: none;
    }
  }

  @include for-size(phone) {
    flex-direction: column;
  }

  &__left {
    box-sizing: border-box;
    width: 370px;
    border-right: 1px solid #b8c2c0;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0) 70.43%), url("../../../../assets/images/myPlan/skipQuestionBackground.jpg") no-repeat center center;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    @include for-size(tablet-phone) {
      width: 100%;
      background-position: center center;
      max-width: 370px;
    }

    @include for-size(phone) {
      max-width: 100%;
      flex: 0 0 200px;
    }

    @include for-size(small-phone) {
      background-position: top center;
      flex: 0 0 190px;
    }


    &-title {
      cursor: pointer;
      text-align: center;
      color: white;
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      margin-top: auto;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;

      img {
        height: 20px;
        filter: invert(1);
      }
    }

    &-description {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__right {
    max-width: 395px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include for-size(tablet-phone) {
      width: 100%;
      max-width: 100%;
      margin-top: 30px;
    }

    &-title {
      font-family: 'SFProDisplay-Semibold', sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      margin: 0;
      color: #000000;

      @include for-size(small-phone) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &-button {
      font-family: 'SFProDisplay-Bold', sans-serif;
      font-weight: 700;
      background: black;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin: 70px 0 50px 0;
      width: 100%;
      max-width: 315px;

      @include for-size(tablet-phone) {
        margin-top: 30px;
        margin-bottom: 20px;
        max-width: 100% !important;
        width: 100% !important;
      }

      @include for-size(phone) {
        margin-top: 20px;
      }
    }

    &-description {
      font-size: 18px;
      line-height: 21px;
      color:  #8F8F8F;

      @include for-size(tablet-phone) {
        text-align: center;
        font-size: 14px;
        line-height: 21px;
      }
      @include for-size(phone) {
        text-align: center;
        font-size: 14px;
        line-height: 15px;

      }

      span {
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
