@import '../../../../assets/styles/variables';

.options-filter {
  display: flex;
  background: #fff;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 90px;

  @include for-specific-size(1400px) {
    padding: 0 30px;
  }


  @include for-size(tablet-phone) {
    flex-wrap: wrap;
    background: transparent;
  }

  &__type {
    list-style: none;
    display: flex;
    padding: 0;

    @include for-size(tablet-phone) {
      width: 100%;
      margin-bottom: 10px;
      position: relative;

      &:after {
        content: '';
        left: -30px;
        position: absolute;
        height: 1px;
        background: rgba(#B8C2C0, .35);
        bottom: -5px;
        width: 100vw;
      }
    }

    &-item {
      padding: 0 20px;
      margin: 0 5px;
      font-size: 16px;
      line-height: 24px;
      color: $color-text-primary;
      cursor: pointer;
      @include for-size(tablet-phone) {
        width: auto;
        padding: 0;
        margin: auto;
        text-align: center;
      }

      &:hover {
        color: #000;
      }
    }

    &-active {
      color: #000;
      font-weight: 600;
      font-family: 'SFProDisplay-Semibold', sans-serif;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #FE6D02;
        @include for-size(tablet-phone) {
          bottom: -5px;
        }
      }
    }
  }

  &__difficulty, &__length-workout {
    display: flex;
    align-items: flex-end;

    @include for-specific-size(1024px) {

    }

    &-title {
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #8F8F8F;
      margin: 0 10px 0 0;
    }

    &-title, &-input {
      margin-bottom: 15px;
    }
  }

  &__difficulty {
    margin-left: auto;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    padding: 0 10px;
    display: flex;
    justify-content: center;

    @include for-size(tablet-phone) {
      margin: 0;
      border: none;
      padding: 0;
    }

    &-input {
      width: 60px;
    }
  }

  &__length-workout {
    display: flex;
    padding: 0 10px 0 35px;


    @include for-size(tablet-phone) {
      border: none;
      align-items: flex-end;
      padding: 0;
      justify-content: center;
    }

    &-input {
      width: 110px;
    }
  }
}
