@import '../../../assets/styles/variables';

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  outline: none;
  border: none;
  font-style: normal;
  border-radius: 6px;
  font-weight: bold;
  font-family: 'SFProDisplay-Semibold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $color-text-white;
  cursor: pointer;
  transition: .3s all;

  &[disabled] {
    cursor: not-allowed;
    background: #9b9b9b;
  }

  &:hover {
    opacity: 0.9;
  }

  &-l {
    width: 100%;
    padding: 17px 0;
  }

  &-primary {
    cursor: pointer;
    background: $color-background-light;
    border-radius: 60px;
    color: $color-text-white;
    font-size: 14px;
    line-height: 20px;
  }

  &-challenges {
    background: #FE6D02;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    border-radius: 60px;
    Width: 189px;
    Height: 48px;
    @include for-size(tablet-phone) {
      width: 90%;
      height: 55px;

    }
  }

  &-active {
    cursor: pointer;
    background: #fff;
    border-radius: 60px;
    color: $color-border-quaternary;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4), 0 0;
  }

  &-secondary {
    background: $color-background-POWDER_DUST;
    border-radius: 60px;
    font-weight: bold;
    font-family: 'SFProDisplay-Semibold', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $color-text-white;
  }

  &-gray-blek {
    background: rgba(18, 18, 29, 0.1);
    border-radius: 60px;
    text-align: center;
    color: #12121d;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &-black {
    background: #000;
    color: #fff;
    border-radius: 60px;

  }

}
