@import '../../assets/styles/variables';

.customs-select {
  border: none;
  position: relative;
  z-index: 9;
  width: 60%;
  border: none;

  &__container {
    &_item {
      color: $color-border-quaternary;
      font-size: 13px;
      font-family: 'SFProDisplay-Semibold', sans-serif;
    }
  }

  &__control {
    border: none;
  }
}

.select {
  width: 100%;

  .customs-select__control {
    position: relative;
  }

  .customs-select__value-container {
    position: relative;
    top: 10px;
  }

  .customs-select {
    width: 100%;

    &__control {
      border: none;
      background: none;
      border-radius: 0;
      border-color: rgba(18, 18, 29, 0.3) !important;
      height: 30px;
      box-shadow: none;
      border-bottom: 1px solid rgba(18, 18, 29, 0.3);
    }

    &__single-value {
      border: none;
      height: 70%;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding-bottom: 0;
      position: relative;
      width: 0;
      height: 0;

      svg {
        color: #FE6D02;
        top: 30%;
        left: -50%;
        transform: translate(50% 50%);
        width: 15px;
        height: 15px;
        position: absolute;
      }
    }
  }
}
