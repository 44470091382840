@import '../../../../assets/styles/variables';

.forgot-page {
  position: relative;
  box-sizing: border-box;
  display: flex;
  background: white;
  flex-direction: column;
  width: 420px;
  margin: auto;
  padding: 40px 45px 40px 45px;
  border-radius: 28px;

  button[type='submit'] {
    background: #FE6D02;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @include for-size(small-phone) {
    width: 335px;
    padding: 45px 20px 20px;
    margin: 120px 20px;
  }

  &-logo {
    filter: invert(1);
    height: 30px;
    margin: 0;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, -100%);

    @include for-size(small-phone) {
      height: 17px;
      top: -45px;
    }
  }

  form {
    input:focus {
      border: 1px solid #FE6D02 !important;

      & + img {
        filter: invert(44%) sepia(69%) saturate(2028%) hue-rotate(359deg) brightness(102%) contrast(107%);
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    margin: 0 auto 30px;
    display: block;
    font-family: 'SFProDisplay-Bold', sans-serif;

    @include for-size(small-phone) {
      text-align: left;
      margin: 0 auto 25px;
    }
  }

  &__to-registration {
    display: flex;
    align-items: center;
    flex-direction: column;

    &-title {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-text-primary;
      margin: 20px 0 0 0;
    }

    &-btn {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-text-primary;
      margin: 0;
      text-decoration: underline;
      background: none;
      padding: 0;


      &:hover {
        opacity: 0.9;
      }
    }
  }
}
