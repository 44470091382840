@import '../../../../../assets/styles/variables';

.completed-day {
  display: flex;
  flex-direction: column;

  &__current_day {
    margin-top: 50px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 40px;
    }

    b {
      margin-bottom: 15px;
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 24px;
      color: #000000;
    }

    p {
      max-width: 420px;
      font-family: 'SF Pro Display', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: #8F8F8F;

      a {
        font-family: inherit;
        font-size: 20px;
        color: inherit;
        font-weight: 700;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;

    @include for-size(tablet-phone) {
      justify-content: center;
    }

    &-left {
      height: auto;
      background: #000000;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 20px;
      flex: 0 0 620px;

      @include for-size(small-desktop) {
        flex: 0 0 480px;
      }

      @include for-size(tablet-phone) {
        padding: 20px 0;
        flex: 0 0 75%;
      }

      @include for-size(phone) {
        flex: 0 0 100%;
      }

      &__item {
        flex: 1 1 auto;

        @include for-size(tablet-phone) {
          margin: auto;
        }

        &:first-child {
         border-right: 1px solid #414141;
        }

        &:nth-child(2) {
/*          border-right: 1px solid #414141;
          border-left: 1px solid #414141;*/

          @include for-size(small-phone) {
            border: none;
            padding: 0;
          }
        }

        &-title {
          color: white;
          font-weight: bold;
          font-family: 'SFProDisplay-Semibold', sans-serif;
          font-style: normal;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          margin: 0 0 5px 0;
        }

        &-subtitle {
          text-align: center;
          font-family: 'SF Pro Display', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          color: #ADADAD;
          @include for-size(tablet-phone) {
            font-size: 13px;
          }
        }
      }
    }

    &-view-plan {
      border: 1px solid $color-border-tertiary;
      box-sizing: border-box;
      border-radius: 21px;
      max-width: 348px;
      width: 100%;
      height: 68px;
      background-color: $color-background-white;
      cursor: pointer;
      font-weight: bold;
      font-family: 'SFProDisplay-Semibold', sans-serif;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: $color-text-quaternary;
      transition: all 0.4s linear;
      position: relative;

      &:before {
        position: absolute;
        content: url('../../../../../assets/images/myPlan/arrow.svg');
        right: 30%;
        top: 35%;
        transition: all 0.3s linear;
      }

      &:hover {
        color: $color-text-white;
        background-color: $color-border-tertiary;

        &:before {
          right: 10%;
        }
      }

      &-desktop {
        @include for-size(tablet-phone) {
          display: none;
        }
      }

      &-phone {
        margin-top: 25px;
        display: none;
        @include for-size(tablet-phone) {
          width: 100%;
          height: 70px;
          display: block;
          max-width: 100%;
        }
      }
    }
  }

  &__difficulty {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000;
    margin: 34px 0 45px 0;
    @include for-size(tablet-phone) {
      margin: 15px 0;
    }

    &-count {
      font-weight: bold;
      font-family: 'SFProDisplay-Semibold', sans-serif;
      font-size: 18px;
      margin: 0 0 0 7px;
    }
  }

  &__workout {
    display: flex;
    flex-direction: column;

    &_list {
      display: flex;
      align-items: center;
      column-gap: 30px;
      flex-wrap: wrap;
    }

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #8F8F8F;
      margin: 0 0 19px 0;
    }

    &-body {
      border: 1px solid $color-border-light;
      box-sizing: border-box;
      border-radius: 20px;
      width: 384px;
      flex: 0 0 380px;
      height: 123px;
      display: flex;
      column-gap: 30px;

      @include for-size(phone) {
        flex: 0 0 100%;
        column-gap: 22px;
        height: 90px;
      }

      &-left {
        cursor: pointer;
        background: $color-background-primary;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include for-size(phone) {
          flex: 0 1 33%;
        }

        &__img {
          width: 100%;
          height: 100%;
          border-radius: inherit;
          object-fit: cover;
        }
      }

      &-right {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 auto;

        &__title {
          font-family: 'SFProDisplay-Semibold', sans-serif;
          margin-bottom: 13px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #000000;

          @include for-size(phone) {
            margin-bottom: 4px;
            line-height: 19px;
          }
        }

        &__content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 55px;


          &_status {
            height: inherit;
            width: 55px;
            padding-left: 0;


            @include for-specific-size(1024px) {
              span {
                padding-bottom: 3px;
              }
            }

            @include for-size(phone) {
              margin-top: 0;
              width: 50px;

              span {
                padding-bottom: 2px;
              }
            }
          }

          &-right {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $color-text-primary;
          }
        }
      }
    }
  }
}
