@import '../../../assets/styles/variables';

.textarea {
  position: relative;
  &-label {
  }

  &-error {
  }

  &-item {
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    height: 20px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(18, 18, 29, 0.1);
    &:focus{
      border-bottom: 1px solid #FE6D02;
    }
    padding: 0;
    &::placeholder {
      font-size: 14px;
      line-height: 21px;
      color: $color-text-light;
    }
  }
  &-errors {
    position: absolute;
    color: red;
    font-size: 12px;
    line-height: 16px;
    bottom: -15px;
    left: 12px;
  }
  &-fine {
  }
}
