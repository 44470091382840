@import '../../../../assets/styles/variables';

.device {
  box-sizing: border-box;
  padding: 60px 40px;
  height: 100%;

  @include for-size(tablet-phone) {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 30px;
    background: #fff;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 100;
  }

  &__content {
    box-sizing: border-box;
    height: 100%;
    padding: 0 0 60px 0;

    &-back {
      display: flex;
      margin-bottom: 40px;
      cursor: pointer;
    }

    &-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      color: #000000;
      font-family: 'SFProDisplay-Semibold', sans-serif;
    }

    &__hello {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      flex-direction: column;

      img {
        max-width: 200px;
        margin-bottom: 50px;
        margin-top: 0;
      }

      p, b {
        text-align: center;
        margin-top: 0;
        font-style: normal;
      }

      b {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 22px;
        line-height: 24px;
        color: #000000;
      }

      p {
        margin-bottom: 60px;
        max-width: 320px;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #8F8F8F;
      }

      button {
        padding: 15px 80px;
        color: white;
        background: black;
        border-radius: 60px;
        cursor: pointer;

        @include for-specific-size(400px) {
          width: 315px;
        }
      }
    }

    &__verification {
      height: 100%;

      p, small, input, button {
        display: block;
      }

      p, small {
        font-style: normal;
        font-weight: 400;
        margin-top: 0;
      }

      p {
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: flex-end;
        color: #000000;
        margin-bottom: 25px;
      }

      small {
        margin-bottom: 70px;
        font-size: 14px;
        line-height: 21px;
        color: #8F8F8F;
        max-width: 390px;
      }

      input {
        max-width: 440px;
        padding: 0 0 15px 0;
        margin-top: 0;
        margin-bottom: 85px;
        background: white;
        border-bottom: 1px solid rgba(#FE6D02, .3);
        outline: none;
        border-left: none;
        border-right: none;
        border-top: none;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 17px;
        color: #000000;
        text-transform: uppercase;

        &::placeholder {
          opacity: .3;
        }

        &:focus {
          border-bottom: 1px solid rgba(#FE6D02, 1);
        }
      }

      button {
        padding: 15px 80px;
        color: white;
        background: black;
        border-radius: 60px;
        cursor: pointer;
      }

      @include for-specific-size(400px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        p {
          font-family: 'SFProDisplay-Semibold', sans-serif;
          font-weight: 500;
          text-align: center;
          letter-spacing: .6px;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        small {
          text-align: center;
          max-width: 290px;
        }

        input {
          width: 100%;
          max-width: 100%;
          text-align: center;
        }

        button {
          margin-top: auto;
          width: 315px;
        }
      }
    }

    &__list {
      @include for-specific-size(400px) {
        display: flex;
        flex-direction: column;
      }
      height: 100%;

      &__watch {
        max-width: 350px;
        padding: 20px 22px;
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        column-gap: 22px;
        margin-bottom: 33px;

        img {
          width: 22px;
        }

        &__info {
          b, small {
            display: block;
          }

          b {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
          }

          small {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #8F8F8F;
          }
        }

        @include for-specific-size(400px) {
          max-width: 100%;
          width: 100%;
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #8F8F8F;
      }

      button {
        margin-top: 70px;
        padding: 15px 80px;
        color: white;
        background: black;
        border-radius: 60px;
        cursor: pointer;
      }

      @include for-specific-size(400px) {
        button {
          margin-top: auto;
          width: 315px;
        }

        p {
          text-align: center;
          max-width: 290px;
        }
      }
    }
  }
}
