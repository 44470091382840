@import '../../assets/styles/variables';

.workouts {
  background: $color-background-white;
  border-radius: 60px;

  &__header {
    display: flex;
    padding: 45px 28px 30px 90px;
    @include for-size(tablet-phone) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 3%;
    }

    &-title-container {
      @include for-size(tablet-phone) {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #000;
      }

      img {
        width: 20px;
        height: 23px;
        display: none;
        @include for-size(tablet-phone) {
          display: block;
          margin: 0 10px 0 35px;
        }
      }
    }

    p {
      max-width: 325px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      margin: auto 30px;
      color: #8F8F8F;
      @include for-size(tablet) {
        width: 50%;
        max-width: 50%;
      }
      @include for-size(phone) {
        width: 80%;
        max-width: 80%;
      }
    }
  }
}
