@import '../../../../../assets/styles/variables';

.off-day {
  display: flex;
  flex-direction: column;

  @include for-size(tablet-phone) {
    margin-top: 15px;
  }

  &__content {
    width: 100%;
    max-width: 1280px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 70px auto;
    @include for-size(tablet-phone) {
      margin: 0 0 33px 0;
      background: none;
    }

    &-img {
      border-radius: 50px;
      width: 100%;
      object-fit: cover;
      object-position: center -330px;
      height: 375px;
      margin-top: 53px;
      transition: .3s object-position;

      @include for-specific-size(1415px) {
        object-position: center -300px;
      }

      @include for-specific-size(1340px) {
        object-position: center -230px;
      }

      @include for-specific-size(1220px) {
        object-position: center -180px;
      }

      @include for-specific-size(1200px) {
        object-position: center -230px;
      }

      @include for-specific-size(1130px) {
        object-position: center -150px;
      }

      @include for-specific-size(1023px) {
        margin-top: 23px;
        border-radius: 20px;
        object-position: center -150px;
        height: 315px
      }

      @include for-specific-size(1023px) {
        object-position: center -150px;
      }

      @include for-specific-size(860px) {
        object-position: center -100px;
      }

      @include for-specific-size(750px) {
        object-position: center -30px;
        margin-top: 0;
      }

      @include for-specific-size(630px) {
        object-position: center -110px;
        height: 190px;
      }

      @include for-specific-size(560px) {
        object-position: center -80px;
      }

      @include for-specific-size(530px) {
        object-position: center -50px;
      }

      @include for-specific-size(460px) {
        object-position: center -20px;
      }

      @include for-specific-size(410px) {
        object-position: center 0;
      }
    }
  }

  &__view-plan {
    border: 1px solid $color-border-tertiary;
    box-sizing: border-box;
    border-radius: 21px;
    max-width: 348px;
    width: 100%;
    height: 68px;
    background-color: $color-background-white;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000;
    transition: all 0.4s linear;
    position: relative;
    @include for-size(tablet-phone) {
      max-width: 100%;
    }

    &:before {
      position: absolute;
      content: url('../../../../../assets/images/myPlan/arrow.svg');
      right: 30%;
      top: 35%;
      transition: all 0.3s linear;
    }

    &:hover {
      color: $color-text-white;
      background-color: $color-border-tertiary;

      &:before {
        right: 10%;
      }
    }
  }
}
