.Toastify__toast-container {
  width: auto;
  max-width: 400px;
  font-weight: 400 !important;

  @media (max-width: 767px) {
    padding: 0 0 20px 20px;
    width: 100%;
  }

  @media (max-width: 400px) {
    padding: 0 20px 20px;
  }
}

.Toastify__progress-bar--default {
  background: rgb(71, 166, 245);
}

.push-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__btn {
    font-size: 16px;
    padding: 8px;
    color: #f2f2f2;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;
    display: inline-block;
    outline: none;
    min-width: 120px;
    margin-left: 10px;
  }

  &__img {
    margin-right: 10px;
  }

  &__content {
    display: flex;
  }
}
