.wrapper {
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  padding: 0 10px;
  background: white;

  &__header {
    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      margin-bottom: 20px;
    }
  }

  hr {
    margin-bottom: 24px;
    border: 3px solid black;
  }

  p, b, ul, ol {
    box-sizing: border-box;
    color: #323137;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
    font-size: 18px;
    line-height: 20px;
  }

  p,b {
    letter-spacing: 1px;
    text-align: justify;
  }

  b {
    padding-left: 30px;
    margin-top: 36px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 15px;
  }

  ul, ol {
    padding-left: 50px;
    li {
      margin-bottom: 6px;
    }
  }

  small {
    margin-top: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
